import React, { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Space,
  Image,
  Card,
  Button,
  Radio,
  Select,
  Divider,
  Checkbox,
} from "antd";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  StarFilled,
  AndroidFilled,
  AppleFilled,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import phoneMock from "../../../../assets/images/phoneMock.svg";
import androidscreen from "../../../../assets/images/androidscreen.svg";
import ColorPickerGroup from "../../../../components/form/colorPickerGroup/antColorInput";
import GooglePlacesAutoComplete from "../../../../components/GooglePlacesAutoComplete/antPlaces";
import { FormWrapper, UploadFile } from "../../../../components/customAntd";

import { useLoadScript } from "@react-google-maps/api";
import { updateTier } from "../apiUtils";
import { IosPreview, Required, AndroidPreview, ExtraLabels } from "./utils";
import { useAppContext } from "../../../../components/context/app.context";

import farologo from "../../../../assets/images/logo.png";
import bannerImage from "../../../../assets/images/banner-image.jpg";

import "../styles/ant-modal-custom.scss";
import Styles from "../styles/pass.module.scss";

export const PassForm = ({
  form,
  isEdit,
  tierData,
  setTierData,
  programIdEdit,
}) => {
  const { t } = useTranslation();
  const [tiers, setTiers] = useState([]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    libraries: ["places"],
  });
  const { currentUserData, currentLocale, localesList } = useAppContext();
  const { Item } = Form;

  const tierBenefits = Form.useWatch("tier_benefits", form);
  const tierInformation = Form.useWatch("tier_information", form);
  const companyname = Form.useWatch("companyname", form);
  const [activeTier, setActiveTier] = useState(0);
  const [logoImage, setlogoImage] = useState([]);
  const [iconImage, setIconImage] = useState([]);
  const [applelogoimage, setapplelogoimage] = useState([]);
  const [stripImage, setstripImage] = useState([]);
  const [heroimage, setheroimage] = useState([]);

  const [passColor, setPassColor] = useState("#e5dede");
  const [labelsColor, setLabelsColor] = useState("#0c0c0a");
  const [textColor, setTextColor] = useState("#C55FFC");
  const [locations, setLocations] = useState([]);
  const [logoImageSize, setLogoImageSize] = useState("square");

  const [loading, setLoading] = useState(false);
  const [defaultLocale] = useState("en_US");
  const [availableLocales, setAvailableLocales] = useState([]);

  const watchDefaultLanguage = Form.useWatch("default_language", form);
  const watchLocales = Form.useWatch("locales", form);

  const [isFormTocuhed, setIsFormTocuhed] = useState(false);

  const isPassFormTouched =
    isFormTocuhed ||
    form.isFieldsTouched([
      "tier_benefits",
      "tier_information",
      "discount_location",
      "logo_image",
      "strip_image",
      "default_language",
      "locales",
      "logo_image_size",
      "icon_image",
    ]);
  const pointsEnabled = form.getFieldValue("points_enabled");

  useEffect(() => {
    setTiers([...tierData]);
  }, [tierData]);

  const watchLocation = Form.useWatch("discount_location", form);

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    form
      .validateFields([
        "tier_benefits",
        "tier_information",
        "discount_location",
        "logo_image",
        "strip_image",
        "default_language",
        "locales",
        "icon_image",
      ])
      .then(async (values) => {
        const passData = {
          tierBenefits,
          tierInformation,
          logoImage,
          iconImage,
          applelogoimage: applelogoimage ? applelogoimage : [],
          heroimage: heroimage ? heroimage : [],
          stripImage,
          passColor,
          textColor,
          labelsColor,
          locations,
          locales: watchLocales,
          defaultLocaleId: watchDefaultLanguage,
          appleLogoSize: logoImageSize,
          companyName: currentUserData.company.name,
          programName: form.getFieldValue("program_name"),
        };
        const tierDataNew = [...tiers];
        tierDataNew[activeTier] = { ...tiers[activeTier], passData: passData };
        if (isEdit) {
          try {
            let response;
            if (tierDataNew[activeTier].id) {
              response = await updateTier({
                programId: programIdEdit,
                tierData: { ...tierDataNew[activeTier] },
              });
            }

            if (response.status !== (500 || 422)) {
              form.resetFields([
                "tier_benefits",
                "tier_information",
                "discount_location",
                "logo_image",
                "strip_image",
                "default_language",
                "locales",
                "logo_image_size",
                "icon_image",
              ]);
              setIsFormTocuhed(false);
              setTiers(tierDataNew);
              setTierData(tierDataNew);
              message.success("Tier data updated successfully");
            } else {
              throw Error("Error while updating tier");
            }
          } catch (err) {
            setLoading(false);
            message.error(
              "Something went wrong while updating the tier,please try again"
            );
          }
        } else {
          form.resetFields([
            "tier_benefits",
            "tier_information",
            "discount_location",
            "logo_image",
            "strip_image",
            "default_language",
            "locales",
            "logo_image_size",
            "icon_image",
          ]);
          setIsFormTocuhed(false);
          setTiers(tierDataNew);
          setTierData(tierDataNew);
        }
        setLoading(false);
      })
      .catch((errorInfo) => {
        setLoading(false);
        message.error(
          "Please fill all the details in the form fields are required,please try again"
        );
      });
  };

  const onTierSelect = (e, index) => {
    if (index !== activeTier) {
      form.resetFields([
        "tier_benefits",
        "tier_information",
        "discount_location",
        "logo_image",
        "icon_image",
        "strip_image",
        "default_language",
        "locales",
        "logo_image_size",
      ]);
      setActiveTier(index);
    }
  };

  useEffect(() => {
    if (tiers[activeTier]) {
      form.setFieldValue(
        "tier_benefits",
        tiers[activeTier]?.passData?.tierBenefits
      );
      form.setFieldValue(
        "tier_information",
        tiers[activeTier]?.passData?.tierInformation
      );
      form.setFieldValue(
        "discount_location",
        tiers[activeTier]?.passData?.locations
      );
      form.setFieldValue("locales", tiers[activeTier].passData?.locales);

      form.setFieldValue(
        "default_language",
        tiers[activeTier]?.passData?.defaultLocaleId ?? defaultLocale
      );
      setLabelsColor(tiers[activeTier]?.passData?.labelColor ?? "#0c0c0a");
      setPassColor(tiers[activeTier]?.passData?.passColor ?? "#e5dede");
      setTextColor(tiers[activeTier]?.passData?.textColor ?? "#C55FFC");
      setlogoImage(tiers[activeTier]?.passData?.logoImage ?? []);
      setIconImage(tiers[activeTier]?.passData?.iconImage ?? []);
      setstripImage(tiers[activeTier]?.passData?.stripImage ?? []);
      setapplelogoimage(tiers[activeTier]?.passData?.applelogoimage ?? []);
      setheroimage(tiers[activeTier]?.passData?.heroimage);
      setLogoImageSize(tiers[activeTier]?.passData?.appleLogoSize ?? "square");
      if (tiers[activeTier].passData?.logoImage) {
        form.setFieldValue("logo_image", tiers[activeTier].passData?.logoImage);
      }
      if (tiers[activeTier].passData?.iconImage) {
        form.setFieldValue("icon_image", tiers[activeTier].passData?.iconImage);
      }
      if (tiers[activeTier].passData?.stripImage) {
        form.setFieldValue(
          "strip_image",
          tiers[activeTier].passData?.stripImage
        );
      }
    }
  }, [activeTier, tiers]);

  useEffect(() => {
    setLocations(watchLocation);
  }, [watchLocation]);

  const [mode, setMode] = useState("ios");
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  // Get all locales
  useEffect(() => {
    if (localesList) {
      const localesData = localesList.map((locale) => ({
        ...locale,
        isSelected: isEdit
          ? activeTier?.locales?.find((localeEl) => {
              return localeEl.localeId === locale.value;
            }) || activeTier.defaultLocaleId === locale.value
          : locale.value === defaultLocale,
      }));
      setAvailableLocales(localesData);
    }
  }, [defaultLocale, activeTier, localesList]);

  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  return (
    <>
      <div
        className={Styles["pass-form-container"]}
        style={{ display: "flex", margin: "0px -26px -0px -26px" }}
      >
        <div style={{ flex: "1" }}>
          <Row>
            <Col lg={7} className={Styles["tierFormContainer"]}>
              <p className={Styles["tierCardsHeading"]}>
                {t("PROGRAM_UI.TIERS")}
              </p>
              <div className={Styles["tierCardsList"]}>
                {tiers.map((item, index) => {
                  const currentLocaleData = item?.passData?.locales?.find?.(
                    (locale) => locale.localeId === currentLocale
                  );

                  return (
                    <Card
                      onClick={(e) => onTierSelect(e, index)}
                      className={
                        activeTier === index
                          ? `${Styles["tierCardActive"]} ${
                              Styles["tierSingleCard"]
                            } ${
                              !item.isSavedToDb ? Styles["tierCardError"] : ""
                            } `
                          : `${Styles["tierSingleCard"]} ${
                              !item.isSavedToDb ? Styles["tierCardError"] : ""
                            }`
                      }
                    >
                      <Row
                        align={"middle"}
                        className={Styles["tier-single-card-body"]}
                      >
                        <StarFilled
                          key={index}
                          className={Styles["tierCardIcon"]}
                        ></StarFilled>
                        <p> {currentLocaleData?.name ?? item.tierName}</p>
                      </Row>
                    </Card>
                  );
                })}
              </div>
            </Col>
            <Col lg={17} className={Styles["addpassform"]}>
              <h3>{t("PROGRAM_UI.DEFINE_PASS")}</h3>
              <Item
                label={Required(t("PROGRAM_UI.DEFAULT_LANGUAGE"))}
                name="default_language"
                initialValue={defaultLocale}
                rules={[
                  {
                    required: true,
                    message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  options={availableLocales.map((localEl) => ({
                    ...localEl,
                    disabled: localEl.isSelected,
                  }))}
                  onChange={(value) =>
                    updateLocales(value, watchDefaultLanguage)
                  }
                  placeholder={t("SELECT")}
                />
              </Item>

              <Item
                label={Required(t("PROGRAM_UI.TIER_BENEFITS"))}
                name="tier_benefits"
                extra={t("PROGRAM_UI.PASS_FORM.TIER_BENEFITS_EXTRA")}
                rules={[
                  {
                    required: true,
                    message: t("PROGRAM_UI.PASS_FORM.TIER_BENEFITS_VALIDATION"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "PROGRAM_UI.PASS_FORM.TIER_BENEFITS_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                label={Required(t("PROGRAM_UI.TIER_INFORMATION"))}
                name="tier_information"
                extra={t("PROGRAM_UI.PASS_FORM.TIER_INFORMATION_EXTRA")}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PASS_FORM.TIER_INFORMATION_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "PROGRAM_UI.PASS_FORM.TIER_INFORMATION_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                label={Required(t("PROGRAM_UI.ICON_IMAGE"))}
                name="icon_image"
                extra={
                  <ExtraLabels
                    items={[
                      t("PROGRAM_UI.PASS_FORM.ICON_IMAGE_DIMENSIONS"),
                      t("PROGRAM_UI.PASS_FORM.ICON_IMAGE_EXTRA"),
                      `${t("FILE_TYPES_SUPPORTED")} JPG, JPEG, PNG. ${t(
                        "MAX_SIZE"
                      )}: 1${t("MB")}`,
                    ]}
                  ></ExtraLabels>
                }
                rules={[
                  {
                    required: isEdit && iconImage.length !== 0 ? false : true,
                    message: t("PROGRAM_UI.PASS_FORM.ICON_IMAGE_VALIDATION"),
                  },
                ]}
              >
                <div className={Styles["pass-logo-upload-box"]}>
                  <div style={{ width: "114px" }}>
                    <UploadFile
                      showUploadList={false}
                      fileFor="pass"
                      isLogoImage={false}
                      isIconImage={true}
                      fileSize={1}
                      imageFit={true}
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      height={114}
                      image={iconImage[0]?.resourceUrl}
                      onLoading={(value) => value}
                      onChange={(image) => {
                        setIsFormTocuhed(true);
                        setIconImage([
                          {
                            resourceUrl: image["s3Url-114x114"],
                            type: "iconImage",
                          },
                        ]);
                      }}
                    />
                  </div>
                </div>
              </Item>
              <Item
                label={Required(t("PROGRAM_UI.LOGO_IMAGE"))}
                name="logo_image"
                extra={
                  <ExtraLabels
                    items={[
                      t("PROGRAM_UI.PASS_FORM.LOGO_IMAGE_DIMENSIONS"),
                      t("PROGRAM_UI.PASS_FORM.LOGO_IMAGE_EXTRA"),
                      `${t("FILE_TYPES_SUPPORTED")} JPG, JPEG, PNG. ${t(
                        "MAX_SIZE"
                      )}: 1${t("MB")}`,
                    ]}
                  ></ExtraLabels>
                }
                rules={[
                  ({ getFieldValue }) => ({
                    validator() {
                      const logoImagesExisits =
                        logoImage?.length > 0 && applelogoimage?.length > 0;

                      if (!logoImagesExisits) {
                        return Promise.reject(
                          new Error(
                            t("PROGRAM_UI.PASS_FORM.LOGO_IMAGE_VALIDATION")
                          )
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Checkbox
                  onChange={(e) => {
                    setIsFormTocuhed(true);
                    e.target.checked
                      ? setLogoImageSize("rectangle")
                      : setLogoImageSize("square");
                  }}
                  style={{ marginBottom: "15px" }}
                  checked={logoImageSize === "rectangle" ? true : false}
                >
                  <p>{t("PROGRAM_UI.PASS_FORM.USE_RECTANGULAR_IMAGES")}</p>
                </Checkbox>

                <div className={Styles["pass-logo-upload-box"]}>
                  <div style={{ width: "200px" }}>
                    <UploadFile
                      showUploadList={false}
                      isUploadIpfs
                      fileFor="pass"
                      isLogoImage={true}
                      fileSize={1}
                      imageFit={true}
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      height={180}
                      image={logoImage[0]?.resourceUrl}
                      onLoading={(value) => value}
                      onChange={(image) => {
                        setIsFormTocuhed(true);
                        setlogoImage([
                          {
                            resourceUrl: image["s3Url-660x660"],
                            type: "logoImage",
                          },
                        ]);
                      }}
                    />
                  </div>
                  <div style={{ width: "505px" }}>
                    <UploadFile
                      showUploadList={false}
                      isUploadIpfs
                      fileFor="pass"
                      isLogoImage={true}
                      fileSize={1}
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      height={180}
                      imageFit={true}
                      image={applelogoimage[0]?.resourceUrl}
                      onLoading={(value) => value}
                      onChange={(image) => {
                        setIsFormTocuhed(true);
                        setapplelogoimage([
                          {
                            resourceUrl: image["s3Url-480x150"],
                            type: "applelogoImage",
                          },
                        ]);
                      }}
                    />
                  </div>
                </div>
              </Item>
              <Item
                label={Required(t("PROGRAM_UI.STRIP_IMAGE"))}
                name="strip_image"
                extra={
                  <ExtraLabels
                    items={[
                      t("PROGRAM_UI.PASS_FORM.STRIP_IMAGE_DIMENSIONS"),
                      t("PROGRAM_UI.PASS_FORM.STRIP_IMAGE_EXTRA"),
                      `${t("FILE_TYPES_SUPPORTED")} JPG, JPEG, PNG. ${t(
                        "MAX_SIZE"
                      )}: 1${t("MB")}`,
                    ]}
                  ></ExtraLabels>
                }
                rules={[
                  {
                    required: isEdit && stripImage.length !== 0 ? false : true,
                    message: t("PROGRAM_UI.PASS_FORM.STRIP_IMAGE_VALIDATION"),
                  },
                ]}
              >
                <UploadFile
                  showUploadList={false}
                  isUploadIpfs
                  fileFor="pass"
                  isLogoImage={false}
                  fileSize={100}
                  accept=".jpg, .jpeg, .png, .gif, .svg"
                  height={180}
                  imageFit={true}
                  image={stripImage[0]?.resourceUrl}
                  onLoading={(value) => value}
                  onChange={(image) => {
                    setIsFormTocuhed(true);
                    setstripImage([
                      {
                        resourceUrl: image["s3Url-1125x432"],
                        type: "stripImage",
                      },
                    ]);
                    setheroimage([
                      {
                        resourceUrl: image["s3Url-1032x336"],
                        type: "heroImage",
                      },
                    ]);
                  }}
                />
              </Item>

              <ColorPickerGroup
                label={Required(t("PROGRAM_UI.PASS_COLOR"))}
                description={t("PROGRAM_UI.PASS_FORM.PASS_COLOR_DESC")}
                defaultColor={passColor}
                onChange={(color) => {
                  setIsFormTocuhed(true);
                  setPassColor(color);
                }}
                isCompactMode={true}
              />
              <ColorPickerGroup
                label={Required(t("PROGRAM_UI.LABEL_COLOR"))}
                description={t("PROGRAM_UI.PASS_FORM.LABEL_COLOR_DESC")}
                defaultColor={labelsColor}
                onChange={(color) => {
                  setIsFormTocuhed(true);
                  setLabelsColor(color);
                }}
                isCompactMode={true}
              />
              <ColorPickerGroup
                label={Required(t("PROGRAM_UI.TEXT_COLOR"))}
                description={t("PROGRAM_UI.PASS_FORM.TEXT_COLOR_DESC")}
                defaultColor={textColor}
                onChange={(color) => {
                  setIsFormTocuhed(true);
                  setTextColor(color);
                }}
                isCompactMode={true}
              />

              <Item label={t("LOCATION.TITLE")}>
                <Form.List name="discount_location">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(
                        ({ key, name, value, ...restField }, index) => (
                          <Space
                            key={key}
                            style={{ display: "flex", marginBottom: 8 }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("LOCATION.MISSING_NAME"),
                                },
                              ]}
                            >
                              {isLoaded && (
                                <GooglePlacesAutoComplete
                                  index={index}
                                  form={form}
                                  defaultValue={
                                    watchLocation && watchLocation[index]?.name
                                  }
                                />
                              )}
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "latitude"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("LOCATION.MISSING_LAT"),
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "4.5rem" }}
                                disabled={true}
                                placeholder={t("LOCATION.LAT")}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "longitude"]}
                              rules={[
                                {
                                  required: true,
                                  message: t("LOCATION.MISSING_LONG"),
                                },
                              ]}
                            >
                              <Input
                                style={{ width: "4.5rem" }}
                                disabled={true}
                                placeholder={t("LOCATION.LONG")}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "lockScreenMessage"]}
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "PROGRAM_UI.PASS_FORM.MISSING_LOCKSCREEN_MESSAGE"
                                  ),
                                },
                              ]}
                            >
                              <Input
                                placeholder={t(
                                  "PROGRAM_UI.PASS_FORM.LOCKSCREEN_MESSAGE"
                                )}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        )
                      )}
                      <Form.Item>
                        <Button
                          type="primary"
                          disabled={fields.length === 10}
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          {t("LOCATION.ADD")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Item>

              <FormWrapper
                heading={t("LANGUAGE_OPTIONS.HEADING")}
                description={t("LANGUAGE_OPTIONS.DESCRIPTION")}
              >
                <Form.List name="locales">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <Fragment key={key}>
                          <Row gutter={[13]}>
                            <Col lg={12}>
                              <Item
                                label={`${t("LANGUAGE")} :`}
                                name={[name, "localeId"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t(
                                      "LANGUAGE_OPTIONS.SELECT_LANGUAGE"
                                    ),
                                  },
                                ]}
                              >
                                <Select
                                  options={availableLocales.map((localEl) => ({
                                    ...localEl,
                                    disabled: localEl.isSelected,
                                  }))}
                                  onChange={(value) =>
                                    updateLocales(
                                      value,
                                      watchLocales[index]?.localeId
                                    )
                                  }
                                  placeholder={t("SELECT")}
                                />
                              </Item>
                            </Col>
                            <Col lg={12}>
                              <Item
                                label={`${t("LANGUAGE_OPTIONS.NAME")}:`}
                                name={[name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: t("LANGUAGE_OPTIONS.ENTER_NAME"),
                                  },
                                ]}
                              >
                                <Input placeholder={t("NAME")} />
                              </Item>
                            </Col>
                            <Col lg={12}>
                              <Item
                                label={`${t("LANGUAGE_OPTIONS.DESC")}:`}
                                name={[name, "description"]}
                              >
                                <Input placeholder={t("DESCRIPTION")} />
                              </Item>
                            </Col>
                            <Col lg={12}>
                              <Item
                                label={`${t(
                                  "PROGRAM_UI.PASS_FORM.LOCALISED_INFORMATION"
                                )}:`}
                                name={[name, "information"]}
                              >
                                <Input
                                  placeholder={t("PROGRAM_UI.TIER_INFORMATION")}
                                />
                              </Item>
                            </Col>
                            <Col lg={12}>
                              <Item
                                label={`${t(
                                  "PROGRAM_UI.PASS_FORM.LOCALISED_BENEFITS"
                                )}:`}
                                name={[name, "tierBenefits"]}
                              >
                                <Input placeholder={t("BENEFITS")} />
                              </Item>
                            </Col>
                          </Row>

                          <Item>
                            <Button
                              type="link"
                              icon={<DeleteOutlined />}
                              style={{
                                color: "var(--color-form-error)",
                                placeContent: "flex-end",
                                marginTop: -30,
                                float: "right",
                              }}
                              onClick={() => {
                                setAvailableLocales((prev) => {
                                  return prev.map((item) =>
                                    item.value === watchLocales[index].localeId
                                      ? {
                                          ...item,
                                          isSelected: false,
                                        }
                                      : item
                                  );
                                });
                                remove(name);
                              }}
                            >
                              {t("LANGUAGE_OPTIONS.DELETE_LOCALE")}
                            </Button>
                          </Item>
                        </Fragment>
                      ))}

                      {fields.length !== 0 && (
                        <Divider
                          style={{
                            margin: "-30px -26px 10px -26px",
                            width: "calc(100% + 52px)",
                            borderColor: "#D9D9D9",
                          }}
                        />
                      )}

                      <Item style={{ marginBottom: 0 }}>
                        <Button
                          type="link"
                          onClick={fields.length >= 3 ? () => {} : () => add()}
                          icon={<PlusOutlined />}
                          style={{ color: "var(--color-brand-primary)" }}
                          disabled={fields.length >= 3}
                        >
                          {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                        </Button>
                      </Item>
                    </>
                  )}
                </Form.List>
              </FormWrapper>

              <Button
                type="primary"
                disabled={!isPassFormTouched}
                loading={loading}
                style={{ width: "10rem" }}
                onClick={onSubmit}
              >
                {t("SAVE")}
              </Button>
            </Col>
          </Row>
        </div>
        <div className={Styles["preview-container"]}>
          <div className={Styles["phone-mode-container"]}>
            <Radio.Group
              onChange={handleModeChange}
              value={mode}
              style={{ marginBottom: ".8rem" }}
            >
              <Radio.Button value="ios">
                {" "}
                <AppleFilled className={Styles["pass-apple-icon"]} />
                {t("PROGRAM_UI.PASS_FORM.APPLE")}
              </Radio.Button>
              <Radio.Button value="andriod">
                <AndroidFilled className={Styles["pass-android-icon"]} />
                {t("PROGRAM_UI.PASS_FORM.ANDROID")}
              </Radio.Button>
            </Radio.Group>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              position: "relative",
              flexFlow: "column",
            }}
          >
            <Image
              preview={false}
              className={Styles["phone-mockup"]}
              src={mode === "ios" ? phoneMock : androidscreen}
            ></Image>
            <div className={Styles["pass-card-container"]}>
              {mode === "ios" ? (
                <IosPreview
                  logoImage={
                    logoImageSize === "rectangle"
                      ? (applelogoimage && applelogoimage[0]?.resourceUrl) ??
                        farologo
                      : logoImage[0]?.resourceUrl ?? farologo
                  }
                  stripImage={stripImage[0]?.resourceUrl ?? bannerImage}
                  passColor={passColor}
                  labelsColor={labelsColor}
                  textColor={textColor}
                  pointsEnabled={pointsEnabled}
                />
              ) : (
                <AndroidPreview
                  logoImage={logoImage[0]?.resourceUrl ?? farologo}
                  stripImage={stripImage[0]?.resourceUrl ?? bannerImage}
                  passColor={passColor}
                  labelsColor={labelsColor}
                  textColor={textColor}
                  companyName={companyname}
                  programName={form.getFieldValue("program_name")}
                  pointsEnabled={pointsEnabled}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
