import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import ProxyAccountIndicator from "../components/proxyAccountIndicator";
import { useAppContext } from "../components/context/app.context";
import { routesConfig } from "./config";
import { hasRequiredPermissions } from "../utils/helper";

const PrivateRoutes = () => {
  const proxyCompanyId = localStorage.getItem("proxyCompanyId");
  const { reload, setReload, currentUserRole, currentUserData } =
    useAppContext();

  useEffect(() => {
    let mounted = true;
    if (reload && mounted) {
      setReload(false);
    }
    return () => {
      mounted = false;
    };
  }, [reload]);

  const renderRoute = (route) => (
    <Route key={route.path} path={route.path} element={route.component} />
  );

  // Recursively render and flatten nested routes
  const renderNestedRoutes = (routes) => {
    let allRoutes = [];

    routes.forEach((route) => {
      if (
        hasRequiredPermissions(
          currentUserRole.rolePermissions,
          route.permissions
        )
      ) {
        // Add the current route
        allRoutes.push(renderRoute(route));

        // If the route has children, recursively process them
        if (route.children && route.children.length > 0) {
          allRoutes = allRoutes.concat(renderNestedRoutes(route.children));
        }
      }
    });

    return allRoutes;
  };

  return (
    <>
      {!!proxyCompanyId && <ProxyAccountIndicator />}
      {!reload && (
        <Routes>
          {renderNestedRoutes(routesConfig(currentUserData.type))}
          <Route path="*" element={<Navigate replace to="/home" />} />
        </Routes>
      )}
    </>
  );
};

export default PrivateRoutes;
