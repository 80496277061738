import { useEffect, useState } from "react";
import { Col, Form, Row, Input, message } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";

import { BREADCRUMB_STOREFRONT_FAQ } from "../../../../utils/storeFront/constant";
import { getAllFAQ, updateFAQ } from "../apiUtils";
import {
  Button,
  FormWrapper,
  PageHeader,
} from "../../../../components/customAntd";
import { useAppContext } from "../../../../components/context/app.context";

const { Item } = Form;

const StorefrontFAQ = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableLocales, setAvailableLocales] = useState([]);

  const { localesList } = useAppContext();
  // Get all locales
  useEffect(() => {
    if (localesList) {
      setAvailableLocales(localesList);
      setIsLoading(false);
    }
  }, [localesList]);

  useEffect(() => {
    if (availableLocales && availableLocales.length > 0) {
      const getFAQs = async () => {
        setIsLoading(true);
        const data = await getAllFAQ(id);
        if (data) {
          availableLocales.forEach((locale) => {
            form.setFieldValue(
              locale.value,
              data
                .filter((faq) => faq.localeId === locale.value)
                .map((faq) => ({
                  question: faq.question,
                  answer: faq.answer,
                }))
            );
          });
        }
        setIsLoading(false);
      };
      getFAQs();
    }
  }, [availableLocales]);

  const onFinish = async (data) => {
    setIsSubmitting(true);
    const finalData = Object.keys(data).reduce((acc, key) => {
      data[key]?.map((faq) => {
        acc.push({
          ...faq,
          localeId: key,
        });
      });
      return acc;
    }, []);
    const response = await updateFAQ(finalData, id);

    if (response.status !== 200) {
      message.error(`${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`);
    } else {
      navigate("/storefront-builder");
    }

    setIsSubmitting(false);
  };

  return (
    <>
      {isLoading ? (
        <p>{`${t("LOADING")}...`}</p>
      ) : (
        <>
          <PageHeader
            title={t("STOREFRONT_UI.FAQ")}
            breadcrumbs={BREADCRUMB_STOREFRONT_FAQ(t)}
            wrapperStyle={{ marginBottom: 40 }}
          />

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError={{ block: "center" }}
            requiredMark={false}
            disabled={isLoading}
          >
            <Row gutter={[32]}>
              <Col span={24}>
                {availableLocales.map((locale) => (
                  <FormWrapper heading={locale.label}>
                    <Item label={`${t("FAQ.TITLE")} :`}>
                      <Form.List name={locale.value}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(
                              ({ key, name, value, ...restField }, index) => (
                                <Row gutter={[32]}>
                                  <Col lg={8}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "question"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("FAQ.MISSING_QUESTION"),
                                        },
                                      ]}
                                    >
                                      <Input placeholder={t("FAQ.QUESTION")} />
                                    </Form.Item>
                                  </Col>
                                  <Col lg={15}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "answer"]}
                                      rules={[
                                        {
                                          required: true,
                                          message: t("FAQ.MISSING_ANSWER"),
                                        },
                                      ]}
                                    >
                                      <MDEditor
                                        overflow={false}
                                        placeholder={t("FAQ.ANSWER")}
                                        previewOptions={{
                                          rehypePlugins: [[rehypeSanitize]],
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col lg={1}>
                                    <MinusCircleOutlined
                                      onClick={() => remove(name)}
                                    />
                                  </Col>
                                </Row>
                              )
                            )}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                              >
                                {t("FAQ.ADD")}
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                    </Item>
                  </FormWrapper>
                ))}

                <Button htmlType="submit" loading={isSubmitting}>
                  {t("FAQ.SAVE")}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default StorefrontFAQ;
