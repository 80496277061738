import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { BREADCRUMB_MERCHANT_CREATE } from "../../../../utils/merchants/constant";
import { Modal, Button, ModalHeader } from "../../../../components/customAntd";
import MerchantForm from "../form";

const NewMerchant = ({ isOpen, onCancel, editData, onSubmitCallback }) => {
  const { t } = useTranslation();
  const formRef = useRef();
  const [disableButtons, setDisableButtons] = useState(false);

  const onClose = () => {
    onCancel();
    if (formRef.current) {
      formRef.current.resetFormFields(); // Reset form submission from modal's OK button
    }
  };

  const onSubmitClick = () => {
    if (formRef.current) {
      formRef.current.submit(); // Trigger form submission from modal's OK button
    }
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={editData ? t("MERCHANT.EDIT") : t("MERCHANT.NEW")}
          breadcrumbs={BREADCRUMB_MERCHANT_CREATE(t)}
        />
      }
      open={isOpen}
      centered
      width={1200}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
      destroyOnClose
      footer={[
        <Button
          buttonType="cancel"
          onClick={onClose}
          key="cancel-button"
          loading={disableButtons}
        >
          {t("CANCEL")}
        </Button>,
        <Button
          onClick={onSubmitClick}
          loading={disableButtons}
          key="create-button"
        >
          {editData ? t("MERCHANT.UPDATE") : t("MERCHANT.CREATE")}
        </Button>,
      ]}
    >
      <MerchantForm
        ref={formRef}
        editData={editData}
        setDisableButtons={setDisableButtons}
        onSubmitCallback={onSubmitCallback}
      />
    </Modal>
  );
};

export default NewMerchant;
