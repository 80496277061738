import { useState, useEffect } from "react";
import { TreeSelect, Form } from "antd";
import { useTranslation } from "react-i18next";

import { useAppContext } from "../context/app.context";
import { formatCategoriesForTreeSelect } from "../../utils/helper";

const CategoryTreeSelect = ({
  name,
  startLevel,
  endLevel,
  excludeId,
  ...rest
}) => {
  const form = Form.useFormInstance();
  const watchValue = Form.useWatch(name, form);
  const { isLoadingCategories, categories, currentLocale } = useAppContext();
  const { t } = useTranslation();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [value, setValue] = useState();
  /*This state variable is used only to manage the expande,md keys incase the
  component is not being used inside a form item. eg. filters
*/

  const findExpandedKeys = (treeData, value, expandedKeys = []) => {
    for (let node of treeData) {
      // If the current node contains the target value
      if (node.value === value) {
        return expandedKeys;
      }

      // If the current node has children, search within children
      if (node.children && node.children.length > 0) {
        const childExpandedKeys = findExpandedKeys(node.children, value, [
          ...expandedKeys,
          node.value,
        ]);

        // If a match is found in the children, return the expanded keys (including parents)
        if (childExpandedKeys) {
          return childExpandedKeys;
        }
      }
    }

    // Return null if no match is found at this level
    return null;
  };

  const handleExpandKeys = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  useEffect(() => {
    if ((watchValue || value) && isOpen) {
      const expandedKeys = findExpandedKeys(treeData, watchValue || value);
      if (expandedKeys) {
        setExpandedKeys(expandedKeys);
      }
    }
  }, [watchValue, isOpen, value]);

  useEffect(() => {
    console.log("ESXLUDE ID from tree select", excludeId);
    setTreeData(
      formatCategoriesForTreeSelect(
        categories,
        startLevel ?? 2,
        endLevel ?? 3,
        currentLocale,
        excludeId
      )
    );
  }, [currentLocale, categories]);

  const handleOnChange = (val) => {
    if (!form) setValue(val);
    // If this component is not being used in a form item
    if (rest.onChange) {
      rest.onChange(val);
    }
  };

  return (
    <TreeSelect
      {...rest}
      placeholder={rest.placeholder ?? t("CATEGORIES_UI.SELECT_PLACEHOLDER")}
      treeData={treeData}
      treeExpandedKeys={expandedKeys}
      onTreeExpand={handleExpandKeys}
      treeNodeFilterProp="title"
      onDropdownVisibleChange={(val) => setIsOpen(val)}
      showSearch={true}
      disabled={isLoadingCategories}
      onChange={handleOnChange}
    />
  );
};

export default CategoryTreeSelect;
