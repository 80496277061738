import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  Upload,
  message,
  Select,
  Image,
} from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import MDEditor from "@uiw/react-md-editor";
import {
  Modal,
  Button,
  ModalHeader,
  UploadFile,
} from "../../../../../components/customAntd";
import rehypeSanitize from "rehype-sanitize";
import { UploadOutlined } from "@ant-design/icons";
import { uploadMultipleFiles } from "../../../../../utils/common/apiUtils";
import { getAllStorefrontList } from "../../../storefrontBuilder/apiUtils";
import { addPressReleaseToDB, updatePressReleaseToDb } from "../apiUtils";

const { Item } = Form;

const NewPressReleaseModal = ({
  isOpen,
  onCancelPressReleaseModal,
  currentEditActiveData,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [storeList, setStoreList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [heroImageUri, setHeroImageUri] = useState("");
  const [beforeUploadFiles, setBeforeUploadFiles] = useState([]);
  const [previewImage, setPreviewImage] = useState();

  const onSubmit = async (data) => {
    const {
      heading,
      contactInfo,
      description,
      startDate,
      endDate,
      storefrontId,
      imageUrls,
    } = data;

    let finalData = {
      heading: heading,
      contactInfo: contactInfo,
      description: description,
      heroImage: heroImageUri,
      imageUrls: imageUrls?.map((el) => el.url) || [],
      startDate: dayjs(startDate).toISOString(),
      endDate: dayjs(endDate).toISOString(),
      storefrontId: storefrontId,
    };

    setIsLoading(true);
    const response = currentEditActiveData
      ? await updatePressReleaseToDb(finalData, currentEditActiveData.id)
      : await addPressReleaseToDB(finalData);
    setIsLoading(false);

    if (response?.status === 200) {
      setBeforeUploadFiles([]);
      setHeroImageUri(null);
      setPreviewImage("");
      if (currentEditActiveData) {
        message.success(t("ANNOUNCEMENT.FORM_UPDATED"));
        onCancelPressReleaseModal({ data: response.data, isEdit: true });
      } else {
        message.success(t("ANNOUNCEMENT.FORM_SUBMITTED"));
        onCancelPressReleaseModal({ data: response.data, isEdit: false });
      }
    } else {
      message.error({
        content: currentEditActiveData
          ? "Couldn't update press release"
          : "Couldn't save press release",
        key: "get-error",
        duration: 2,
      });
    }
  };

  const getStoreFrontList = async () => {
    const getAllStores = await getAllStorefrontList({
      limit: 50,
      offset: 0,
      filterCondition: {
        isArchived: false,
      },
    });

    if (getAllStores?.status === 200) {
      setStoreList(() =>
        getAllStores.data.map((item) => {
          return {
            label: item.title,
            value: item.id,
          };
        })
      );
    }
  };

  useEffect(() => {
    getStoreFrontList();
  }, []);

  useEffect(() => {
    if (currentEditActiveData) {
      form.setFieldsValue({
        heading: currentEditActiveData.heading,
        contactInfo: currentEditActiveData.contactInfo,
        description: currentEditActiveData.description,
        heroImage: currentEditActiveData.heroImage,
        imageUrls: currentEditActiveData.imageUrls.map((url) => ({
          url,
          status: "done",
        })),
        startDate: dayjs(currentEditActiveData.startDate),
        endDate: dayjs(currentEditActiveData.endDate),
        storefrontId: currentEditActiveData.storefrontId,
      });
      setHeroImageUri(currentEditActiveData.heroImage);
    }
  }, [currentEditActiveData, form]);

  const beforeUpload = (file) => {
    const isAllowedType = ["image/jpeg", "image/png", "image/webp"].includes(
      file.type
    );
    if (!isAllowedType) {
      message.error(`${file.name} is not a valid file type.`);
      return Upload.LIST_IGNORE; // Prevent upload
    }

    const isValidSize = file.size <= 5 * 1024 * 1024;
    if (!isValidSize) {
      message.error(`${file.name} exceeds the maximum size of 5MB.`);
      return Upload.LIST_IGNORE; // Prevent upload
    }
    setBeforeUploadFiles((prev) => [...prev, file]);
    return true; // Allow upload
  };

  useEffect(() => {
    const uploadFiles = async () => {
      try {
        const data = {
          fileFor: "pressRelease",
          isUploadIpfs: false,
          files: beforeUploadFiles,
        };
        const response = await uploadMultipleFiles(data);
        if (response?.status === 200) {
          const urlArray =
            response.data?.length > 1 ? response.data : [response.data];
          form.setFieldValue("imageUrls", [
            ...(form
              .getFieldValue("imageUrls")
              ?.filter((el) => el.status === "done") || []),
            ...urlArray.map((el) => ({
              uid: "unique_id" + Math.random().toString(36).substr(2, 9), // A unique identifier for the file
              name: "filename" + Math.random().toString(36).substr(2, 9), // Name of the file
              status: "done", // Upload status ('uploading', 'done', 'error')
              url: el.s3Url, // The URL to the uploaded file on S3
            })),
          ]);
        } else {
          throw new Error("Upload failed");
        }
      } catch (error) {
        form.setFieldValue(
          "imageUrls",
          form.getFieldValue("imageUrls").filter((el) => el.status === "done")
        );
        console.error("Error uploading image:", error);
        message.error({
          content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
          key: "error",
          duration: 2,
        });
      } finally {
        setBeforeUploadFiles([]);
      }
    };
    if (beforeUploadFiles.length > 0) uploadFiles();
  }, [beforeUploadFiles]);

  const handlePreviewImage = (file) => {
    if (file.url) setPreviewImage(file.url);
  };

  const onCancel = () => {
    setBeforeUploadFiles([]);
    setHeroImageUri(null);
    setPreviewImage("");
    onCancelPressReleaseModal(false);
  };

  return (
    <>
      {!!previewImage && (
        <Image
          src={previewImage}
          preview={{
            visible: true,
            onVisibleChange: (val) => {
              setPreviewImage("");
            },
          }}
          alt="Preview Image"
        />
      )}
      <Modal
        destroyOnClose
        onCancel={() => onCancel()}
        open={isOpen}
        centered
        title={<ModalHeader title={t("ANNOUNCEMENT.PRESS_RELEASE")} />}
        width={1200}
        footer={[
          <Button
            buttonType="cancel"
            onClick={() => onCancel()}
            key="cancel-button"
          >
            {t("CANCEL")}
          </Button>,
          <Button
            onClick={() => form.submit()}
            key="create-button"
            loading={isLoading}
          >
            {currentEditActiveData ? t("UPDATE") : t("CREATE")}
          </Button>,
        ]}
      >
        <Row gutter={52}>
          <Col sm={20} md={12}>
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              preserve={false}
            >
              <Item
                name={"heading"}
                label={t("ANNOUNCEMENT.HEADING")}
                rules={[
                  {
                    required: true,
                    message: t("ANNOUNCEMENT.HEADING_REQUIRED"),
                  },
                ]}
              >
                <Input placeholder={t("ANNOUNCEMENT.HEADING")} />
              </Item>
              <Item
                label={t("ANNOUNCEMENT.DESCRIPTION")}
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Description is required.",
                  },
                  {
                    validator: (_, val) => {
                      if (val.length < 10000) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t("ANNOUNCEMENT.DESCRIPTION_LENGTH_VALIDATION")
                      );
                    },
                  },
                ]}
              >
                <MDEditor
                  overflow={false}
                  placeholder={t("ANNOUNCEMENT.DESCRIPTION")}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                />
              </Item>

              <Item
                name="heroImage"
                label={t("ANNOUNCEMENT.UPLOAD_HERO_IMAGE")}
                valuePropName=""
                rules={[
                  {
                    required: true,
                    message: t("ANNOUNCEMENT.HERO_IMAGE_VALIDATION"),
                  },
                ]}
              >
                <UploadFile
                  showUploadList={false}
                  placeholder={t("ANNOUNCEMENT.UPLOAD_HERO_IMAGE")}
                  fileFor="pressRelease"
                  fileSize={5}
                  accept=".jpg, .jpeg, .png, .webp"
                  style={{ width: "144px" }}
                  height={144}
                  image={heroImageUri}
                  imageFit="contain"
                  onLoading={(value) => {}}
                  onChange={(image) => {
                    setHeroImageUri(image.s3Url);
                  }}
                />
              </Item>

              <Item
                name="imageUrls"
                label={t("ANNOUNCEMENT.UPLOAD_IMAGES")}
                valuePropName="fileList"
                getValueFromEvent={({ fileList }) => {
                  return fileList;
                }}
              >
                <Upload
                  name="imageUrls"
                  multiple
                  beforeUpload={beforeUpload}
                  customRequest={() => {}}
                  listType="picture-card"
                  showUploadList={{ showRemoveIcon: true }}
                  accept="image/jpeg,image/png,image/jpg,image/webp"
                  onPreview={handlePreviewImage}
                  defaultFileList={null}
                >
                  <div>
                    <UploadOutlined />
                    <div style={{ marginTop: 8 }}>
                      {t("ANNOUNCEMENT.UPLOAD_IMAGES")}
                    </div>
                  </div>
                </Upload>
              </Item>

              <Item
                name="startDate"
                label={t("START_DATE")}
                rules={[
                  {
                    required: true,
                    message: t("ANNOUNCEMENT.START_DATE_REQUIRED"),
                  },
                ]}
              >
                <DatePicker
                  disabledDate={(date) => date.isBefore(dayjs().startOf("day"))}
                  showTime={{ format: "HH:mm" }}
                  placeholder={t("START_DATE")}
                />
              </Item>

              <Item name="endDate" label={t("END_DATE")}>
                <DatePicker
                  disabledDate={(date) =>
                    date.isBefore(
                      form.getFieldValue("startDate").startOf("day")
                    )
                  }
                  showTime={{ format: "HH:mm" }}
                  placeholder={t("END_DATE")}
                />
              </Item>

              <Item label={t("ANNOUNCEMENT.CONTACTS_INFO")} name="contactInfo">
                <MDEditor
                  overflow={false}
                  placeholder={t("ANNOUNCEMENT.CONTACTS_INFO")}
                  previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                  }}
                />
              </Item>

              <Item
                label={t("ANNOUNCEMENT.SELECT_STOREFRONT")}
                name="storefrontId"
                rules={[
                  {
                    required: true,
                    message: t("ANNOUNCEMENT.STOREFRONT_REQUIRED"),
                  },
                ]}
              >
                <Select
                  options={storeList}
                  placeholder={t("ANNOUNCEMENT.STOREFRONT_REQUIRED")}
                />
              </Item>
            </Form>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default NewPressReleaseModal;
