import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, message, Select, Tabs } from "antd";

import { useAppContext } from "../../../../components/context/app.context";
import { Button, UploadFile } from "../../../../components/customAntd";
import { getStorefrontsForMerchant } from "../apiUtils";
import { uploadUsers } from "../reports/apiUtils";

import Reports from "./reports";

const { Item } = Form;

const UploadUsers = () => {
  const [uploadingFile, setUploadingFile] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();
  const [readyFile, setReadyFile] = useState();
  const [storefronts, setStorefronts] = useState();
  const [loadingStorefronts, setLoadingStorefronts] = useState(true);

  const [form] = Form.useForm();

  const { t } = useTranslation();

  const { currentUserData } = useAppContext();

  const handleUpload = async (data) => {
    setUploadingFile(true);
    const response = await uploadUsers({
      file: uploadedFile,
      password: data.password,
      id: data.storefrontId,
    });

    if (response.status === 200) {
      message.success({
        content: t("MEMBER_ID.FILE_UPLOAD_SUCCESSFULLY"),
        duration: 3,
        key: "success",
      });
      setReadyFile(null);
      setUploadedFile(null);
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        duration: 3,
        key: "error",
      });
    }
    setUploadingFile(false);
  };

  const getStorefronts = async () => {
    setLoadingStorefronts(true);
    const response = await getStorefrontsForMerchant();
    if (response.status === 200) {
      setStorefronts(
        response.data?.data?.map((el) => ({
          value: el.id,
          label: el.title,
        }))
      );
    } else {
      message.error({
        content: t("SOMETHING_WENT_WRONG"),
        duration: 3,
        key: "storefront-error",
      });
    }
    setLoadingStorefronts(false);
  };

  useEffect(() => {
    getStorefronts();
  }, [currentUserData]);

  return (
    <Tabs
      items={[
        {
          label: t("UPLOAD"),
          key: "upload",
          children: (
            <>
              <UploadFile
                fileFor="users"
                showUploadList={false}
                placeholder={t("MEMBER_ID.SELECT_CSV_FILE")}
                fileSize={20}
                accept=".csv"
                // onLoading={(value) => setUploadingFile(value)}
                onChange={(file) => {
                  setUploadedFile(file);
                  setReadyFile(true);
                  message.success({
                    content: t("FILE_UPLOAD.READY_FOR_UPLOAD"),
                    duration: 2,
                    key: "success",
                  });
                }}
                merchantId={currentUserData?.id}
              />
              <Form
                form={form}
                layout="vertical"
                onFinish={handleUpload}
                requiredMark={false}
                style={{ marginTop: 20, width: 300 }}
              >
                <Item
                  name="storefrontId"
                  label={t("STOREFRONT")}
                  rules={[
                    {
                      required: true,
                      message: t("MERCHANT_UI.STOREFRONT_REQUIRED"),
                    },
                  ]}
                >
                  <Select options={storefronts} />
                </Item>
                <Item
                  name="password"
                  label={t("PASSWORD")}
                  rules={[
                    {
                      required: true,
                      message: t("MERCHANT_UI.USERS_PASSWORD_REQUIRED"),
                    },
                    {
                      validator: (_, val) => {
                        if (val && val.length < 8) {
                          return Promise.reject(
                            new Error(t("MERCHANT_UI.PASSWORD_LENGTH"))
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input placeholder={t("ENTER_PASSWORD")} />
                </Item>
                <Button
                  type="primary"
                  loading={uploadingFile}
                  disabled={!readyFile}
                  htmlType="submit"
                >
                  {t("FILE_UPLOAD.UPLOAD_FILE")}
                </Button>
              </Form>
            </>
          ),
        },
        {
          label: t("REPORTS"),
          key: "reports",
          children: (
            <Reports
              loadingStorefronts={loadingStorefronts}
              storefronts={storefronts}
            />
          ),
        },
      ]}
    />
  );
};

export default UploadUsers;
