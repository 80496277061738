import axios from "axios";

import constant from "../../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getMerchantReportUrl = async () => {
  return axios
    .get(`${baseURL}merchants/metabase/merchant-report`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get merchant Report URL error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getMerchantBenefitsReportUrl = async () => {
  return axios
    .get(`${baseURL}merchants/metabase/benefit-report-by-merchant`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get merchant benefits Report URL error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getMerchantUsersReportUrl = async () => {
  return axios
    .get(`${baseURL}merchants/metabase/user-by-point-by-merchant`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get merchant users Report URL error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const getUploadedUsersReport = async (id) => {
  return axios
    .get(`${baseURL}merchants/storefront/${id}/public-users/report-urls`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get merchant users Report URL error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const uploadUsers = async (data) => {
  const { id, file, password } = data;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileFor", "users");
  formData.append("password", password);
  return axios
    .post(`${baseURL}merchants/storefront/${id}/public-users/bulk-upload`, formData)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Get merchant users Report URL error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};
