// import Third party and React
import { useEffect, useMemo, useState } from "react";
import {
  Space,
  Row,
  Col,
  Skeleton,
  Popconfirm,
  notification,
  Tooltip,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// import utils, API Utils and helper functions
import { deleteMerchantFromDb, getAllMerchantsFromDb } from "./apiUtils";
import { debounce } from "../../../utils/helper";

// import custom components
import {
  Button,
  Table,
  Tag,
  Card,
  NoData,
  Micons,
  PageHeader,
} from "../../../components/customAntd";
import MerchantFilters from "./filters";
import NewMerchant from "./newMerchant";
import { useAppContext } from "../../../components/context/app.context";
import { BREADCRUMB_MERCHANTS } from "../../../utils/merchants/constant";
import DefaultImage from "../../../assets/images/default-img.jpg";
import ManageUsers from "./manageUsers";

// import custom Style
import Styles from "./styles/merchant.module.scss";

const { Meta } = Card;

const MerchantPage = () => {
  const [displayType, setDisplayType] = useState("grid");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [merchantForUser, setMerchantForUser] = useState("");
  const [data, setData] = useState([]);
  const [editData, setEditData] = useState();
  const [filters, setFilters] = useState({
    filterCondition: {},
  });
  const { t } = useTranslation();
  const { currentLocale, categories } = useAppContext();

  // useMemo is used to prevent the re-creation of the 'columns' variable on every render.
  const columns = useMemo(
    () => [
      {
        dataIndex: "heroImageUrl",
        width: 100,
        render: (_, record) => (
          <img
            src={
              record?.locales?.find?.(
                (locale) => locale.localeId === currentLocale
              )?.heroImageUrl ??
              record?.heroImageUrl ??
              DefaultImage
            }
            alt="Preview"
            height={50}
            width={50}
            className="table-data-image"
            onClick={() => handleEditClick(record)}
          />
        ),
      },
      {
        title: t("MERCHANT.NAME_COLUMN"),
        dataIndex: "name",
        key: "name",
        width: "30%",
        sorter: (a, b) => a?.title?.length - b?.title?.length,
        render: (_, { name, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.name ?? name,
      },
      {
        title: t("DESCRIPTION_COLUMN"),
        dataIndex: "description",
        key: "description",
        render: (_, { description, locales }) =>
          locales?.find?.((locale) => locale.localeId === currentLocale)
            ?.description ?? description,
      },
      {
        title: t("DATE"),
        key: "startDate",
        dataIndex: "startDate",
        width: "30%",
        render: (_, { startDate }) => (
          <>
            <Tag isDisabled>{dayjs(startDate).format("MM/DD/YYYY")}</Tag>
          </>
        ),
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Space size="middle">
            <Button type="link">
              <Tooltip title={t("EDIT")}>
                <Micons
                  icon="edit"
                  className="table-action-icon"
                  onClick={() => handleEditClick(record)}
                />
              </Tooltip>
            </Button>
            <Popconfirm
              title={t("MERCHANT.CONFIRM_DELETE")}
              onConfirm={() => handleDelete(record.id)}
              onCancel={() => {}}
              okText={t("YES")}
              cancelText={t("NO")}
              placement="topRight"
              arrowPointAtCenter
            >
              <Button type="link">
                <Tooltip title={t("DELETE")}>
                  <Micons icon="delete" className="table-action-icon" />
                </Tooltip>
              </Button>
            </Popconfirm>
          </Space>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categories, t]
  );

  // Get all Merchants initially
  useEffect(() => {
    const fetchMerchants = async () => {
      setIsLoading(true);
      const getAllMerchants = await getAllMerchantsFromDb(filters);
      if (getAllMerchants?.status === 200) {
        setData(getAllMerchants.data);
      }
      setIsLoading(false);
    };
    fetchMerchants();
  }, [filters]);

  // Switching between a list view and a grid view.
  const onViewChange = () => {
    setDisplayType(displayType === "list" ? "grid" : "list");
  };

  // Handler for the cancel button
  const handleCancelClick = () => {
    setIsOpen(false);
    setEditData(null);
  };

  const onSubmitCallback = (data) => {
    if (!!editData) {
      setData((prev) =>
        prev.map((item) => (item.id === data.id ? data.data : item))
      );
    } else {
      setData((prev) => [data.data, ...prev]);
    }
    handleCancelClick();
  };

  // Handler for the edit 'merchant' button
  const handleEditClick = (record) => {
    setEditData(record);
    setIsOpen(true);
  };

  // Handler for the delete 'merchant' button
  const handleDelete = async (recordId) => {
    const deletedMerchant = await deleteMerchantFromDb(recordId);
    if (deletedMerchant?.status === 200) {
      setData((prev) =>
        prev.filter((item) => item.id !== deletedMerchant.data.id)
      );
    } else {
      notification.error({
        message: "Error",
        description: deletedMerchant?.data?.message,
        duration: 30,
        placement: "topRight",
      });
    }
  };

  // Handler for the search input
  const handleSearch = debounce((e) => {
    const searchTerm = e.target.value;
    if (searchTerm !== "") {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            search: searchTerm,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.search;
      setFilters(filtersCopy);
    }
  });

  // Handler for the filter select
  const handleFilterByChange = (value) => {
    if (value) {
      setFilters((prev) => {
        return {
          ...prev,
          filterCondition: {
            ...prev.filterCondition,
            status: value,
          },
        };
      });
    } else {
      const filtersCopy = { ...filters };
      delete filtersCopy.filterCondition.status;
      setFilters(filtersCopy);
    }
  };

  const onUserModalClose = () => {
    setMerchantForUser(null);
  };

  const cardActionButtons = (item) => {
    const actions = [
      <Popconfirm
        title={t("MERCHANT.CONFIRM_DELETE")}
        onConfirm={() => handleDelete(item.id)}
        onCancel={() => {}}
        okText={t("YES")}
        cancelText={t("NO")}
      >
        <Tooltip title={t("DELETE")}>
          <Micons icon="delete" className="table-action-icon" />
        </Tooltip>
      </Popconfirm>,
      <Tooltip title={t("EDIT")}>
        <Button type="link" onClick={() => handleEditClick(item)}>
          <Micons icon="edit" className="table-action-icon" />
        </Button>
      </Tooltip>,
    ];

    if (["Affiliate", "Franchisee"].includes(item.partnerType)) {
      actions.push(
        <Tooltip title={t("MERCHANT.MANAGE_USERS")}>
          <Button type="link" onClick={() => setMerchantForUser(item)}>
            <Micons icon="person" className="table-action-icon" />
          </Button>
        </Tooltip>
      );
    }

    return actions;
  };
  return (
    <>
      <PageHeader
        title={t("MERCHANTS")}
        breadcrumbs={BREADCRUMB_MERCHANTS(t, !!editData)}
        extra={
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t("MERCHANT.NEW")}
          </Button>
        }
      />
      <MerchantFilters
        onViewChange={onViewChange}
        type={displayType}
        onSearch={handleSearch}
        onFilterByChange={handleFilterByChange}
        // setShowCreateMerchant={setShowCreateMerchant}
      />
      {isLoading ? (
        <Skeleton active />
      ) : (
        <>
          {data.length === 0 ? (
            <NoData />
          ) : (
            <>
              {displayType === "list" ? (
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  locale={{
                    triggerDesc: t("SORT_DESCENDING"),
                    triggerAsc: t("SORT_ASCENDING"),
                    cancelSort: t("CANCEL_SORT"),
                  }}
                />
              ) : (
                <Row gutter={[25, 25]}>
                  {data.map((item) => {
                    const currentLocaleData = item?.locales?.find(
                      (locale) => locale.localeId === currentLocale
                    );
                    return (
                      <Col
                        xs={24}
                        md={12}
                        lg={6}
                        key={`${item.name}-${item.id}}`}
                      >
                        <Card
                          className={Styles["card"]}
                          cover={
                            <img
                              alt={item.title}
                              src={
                                currentLocaleData?.heroImageUrl ??
                                item.heroImageUrl
                              }
                              height="200px"
                              width="100%"
                              className="pointer"
                              onClick={() => handleEditClick(item)}
                            />
                          }
                          actions={cardActionButtons(item)}
                        >
                          <Tag className={Styles.tag} isDisabled>
                            {dayjs(item.startDate).format("MM/DD/YYYY")}
                          </Tag>
                          <Meta title={currentLocaleData?.name ?? item.name} />
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              )}
            </>
          )}
        </>
      )}

      <NewMerchant
        isOpen={isOpen}
        onCancel={() => {
          handleCancelClick();
        }}
        onSubmitCallback={onSubmitCallback}
        editData={editData}
      />
      <ManageUsers
        merchantDetails={merchantForUser}
        isOpen={!!merchantForUser}
        onClose={onUserModalClose}
      />
    </>
  );
};

export default MerchantPage;
