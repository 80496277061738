import React, { useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";

import AppContext from "./components/context/app.context";

import RequireAuth from "./components/require.auth/require.auth";
import LandingPage from "./pages/landing.new";
import PrivateLayout from "./components/layouts/private";
import PrivateRoutes from "./routes/privateRoutes";
import Onboarding from "./pages/onboarding";

import { loadLoggedInUser } from "./redux/user.slice";
import {
  buildCategoryHierarchy,
  checkUserRolePermissions,
} from "./utils/helper";
import {
  getLocalesFromDb,
  getMerchantCategoriesFromDb,
} from "./utils/common/apiUtils";
import { ROLE_PERMISSIONS } from "./utils/constants";

import "./i18";
import "react-toastify/dist/ReactToastify.css";
import "./app.css";

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentUserData, setCurrentUserData] = useState(null);
  const [currentUserRoles, setCurrentUserRoles] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [originalRole, setOriginalRole] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [localesList, setLocalesList] = useState([]);
  const [currentLocale, setCurrentLocale] = useState();
  const [proxyCompany, setProxyCompany] = useState();
  const [sidebarCollapsed, setSidebarCollapsed] = useState(
    localStorage.getItem("isCollapsed")
  );
  const [reload, setReload] = useState(false);
  const { i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  // load current user data
  const loadCurrentUser = () => {
    const getUserData = async () => {
      const getToken = localStorage.getItem("authorization");
      const getDefaultWallet = localStorage.getItem("defaultWallet");
      if (getToken) {
        try {
          const userData = await dispatch(loadLoggedInUser());
          if (userData.type === "user/loadLoggedInUser/fulfilled") {
            setCurrentUserData(userData.payload);
            setCurrentUserRoles(userData.payload.roles);
            setCurrentUserRole(userData.payload.roles[0]);
            setOriginalRole(userData.payload.roles[0]);
            setIsLoggedIn(true);
            if (!getDefaultWallet) {
              localStorage.setItem("defaultWallet", "magic");
            }
          }
        } catch (err) {
          console.log(err);
          setIsLoggedIn(false);
        }
      } else {
        setIsLoggedIn(false);
      }
    };
    getUserData();
  };

  const setLanguage = (lang) => {
    i18n.changeLanguage(lang || "en");
  };

  useEffect(() => {
    loadCurrentUser();
  }, []);

  useEffect(() => {
    setLanguage(currentLocale);
  }, [currentLocale]);

  useEffect(() => {
    if (currentUserRole) {
      localStorage.setItem("currentuser-role", currentUserRole.id);
    }
  }, [currentUserRole]);

  useEffect(() => {
    //To redirect to notifications page if the user is a merchant
    if (currentUserData && isLoggedIn && location.pathname === "/") {
      if (
        currentUserData?.merchants?.length > 0 &&
        !checkUserRolePermissions(
          currentUserRole,
          ROLE_PERMISSIONS.VIEWBENEFITS
        ) &&
        checkUserRolePermissions(
          currentUserRole,
          ROLE_PERMISSIONS.VIEWBENEFITNOTIFICATIONS
        ) &&
        checkUserRolePermissions(
          currentUserRole,
          ROLE_PERMISSIONS.MANAGEBENEFITNOTIFICATIONS
        )
      ) {
        navigate("/launchpad/notifications");
      } else {
        if (currentUserData.type === "Merchant") {
          navigate("profile");
        } else {
          navigate("/home");
        }
      }
    }
  }, [isLoggedIn]);

  //Call the common apis on first load after logging in
  useEffect(() => {
    const fetchData = async () => {
      if (!isLoggedIn) return;

      const promises = [
        getLocalesFromDb(),
        checkUserRolePermissions(
          currentUserRole,
          ROLE_PERMISSIONS.VIEWBENEFITS
        ) ||
        checkUserRolePermissions(
          currentUserRole,
          ROLE_PERMISSIONS.VIEWSTOREFRONT
        ) ||
        checkUserRolePermissions(currentUserRole, ROLE_PERMISSIONS.VIEWPROGRAMS)
          ? getMerchantCategoriesFromDb()
          : Promise.resolve(null), // Skip categories if permissions don't match
      ];

      // Execute all promises
      Promise.all(promises)
        .then(([localesResponse, categoriesResponse]) => {
          // Process locales

          if (localesResponse?.status === 200) {
            const localesData = localesResponse.data.map((locale) => ({
              value: locale.localeId,
              label: `${locale.language.language} (${locale.country.country})`,
              isSupported: locale.isSupported,
            }));
            setLocalesList(localesData);
          } else {
            console.error("Failed to fetch locales");
          }

          // Process categories
          if (categoriesResponse) {
            if (categoriesResponse?.status === 200) {
              setCategories(buildCategoryHierarchy(categoriesResponse.data));
            } else {
              message.error({
                content: "Could not fetch categories",
                duration: 3,
                key: "category-fetch-error",
              });
            }
          }

          setIsLoadingCategories(false);
        })
        .catch((error) => {
          console.error("Error with API calls:", error);
          message.error({
            content: "An error occurred while fetching data",
            duration: 3,
            key: "data-fetch-error",
          });
          setIsLoadingCategories(false);
        });
    };

    fetchData();
  }, [isLoggedIn, currentUserRole, ROLE_PERMISSIONS]);

  const handleLogOut = async () => {
    try {
      localStorage.removeItem("currentuser-id");
      localStorage.removeItem("currentuser");
      localStorage.removeItem("currentuser-email");
      localStorage.removeItem("currentuser-role");
      localStorage.removeItem("authorization");

      localStorage.removeItem("collectionCount");
      localStorage.removeItem("selectedTab");
      localStorage.removeItem("user-data");
      localStorage.removeItem("proxyCompanyId");
      localStorage.removeItem("proxyCompanyAdminRoleId");
      localStorage.removeItem("proxyCompanyName");
      setProxyCompany(null);
      setIsLoggedIn(false);
      await navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <AppContext.Provider
        value={{
          loadCurrentUser,
          handleLogOut,
          currentUserData,
          setCurrentUserData,
          userRoles: currentUserRoles,
          setUserRoles: setCurrentUserRoles,
          currentUserRole,
          setCurrentUserRole,
          localesList,
          setLanguage,
          currentLocale,
          setCurrentLocale,
          originalRole,
          proxyCompany,
          setProxyCompany,
          reload,
          setReload,
          sidebarCollapsed,
          setSidebarCollapsed,
          categories,
          setCategories,
          isLoadingCategories,
        }}
      >
        {isLoggedIn === null ? (
          <div className="spin-wrapper">
            <Spin
              tip="Loading..."
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, marginBottom: 10 }}
                  spin
                />
              }
              className="spinner-area"
            />
          </div>
        ) : (
          <>
            {isLoggedIn ? (
              <PrivateLayout onLogOut={handleLogOut}>
                {currentUserData?.company?.companyStatus === "approved" &&
                currentUserData?.company?.status !== "pendingReview" ? (
                  <RequireAuth>
                    <PrivateRoutes />
                  </RequireAuth>
                ) : (
                  <Routes>
                    <Route
                      exact
                      path="/onboarding"
                      element={
                        <Onboarding
                          emailId={currentUserData?.email}
                          companyId={currentUserData?.company?.id}
                        />
                      }
                    />
                    <Route
                      path="*"
                      element={<Navigate replace to="/onboarding" />}
                    />
                  </Routes>
                )}
              </PrivateLayout>
            ) : (
              <Routes>
                <Route exact path="/" element={<LandingPage />} />
                <Route path="*" element={<Navigate replace to="/" />} />
              </Routes>
            )}
          </>
        )}
      </AppContext.Provider>
      <ToastContainer autoClose={10000} style={{ width: "50%" }} />
    </>
  );
};

export default App;
