import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Spin, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import {
  getMerchantBenefitsReportUrl,
  getMerchantReportUrl,
  getMerchantUsersReportUrl,
} from "./apiUtils";

const MerchantReports = () => {
  const [iframeUrls, setIframeUrls] = useState({
    merchant: null,
    benefits: null,
    users: null,
  });
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [tab, setTab] = useState("");

  const [loading, setLoading] = useState({
    merchant: true,
    benefits: true,
    users: true,
  });

  const [errors, setErrors] = useState({
    merchant: null,
    benefits: null,
    users: null,
  });

  const targetRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchAllReports = async () => {
      const reportPromises = [
        // getMerchantReportUrl(),
        getMerchantBenefitsReportUrl(),
        getMerchantUsersReportUrl(),
      ];

      const keys = ["benefits", "users"];

      Promise.all(
        reportPromises.map((promise, index) =>
          promise.then((response) => {
            const key = keys[index];
            setLoading((prev) => ({ ...prev, [key]: false }));
            if (response?.status === 200) {
              setIframeUrls((prev) => ({ ...prev, [key]: response.data }));
            } else {
              setErrors((prev) => ({
                ...prev,
                [key]: `Failed to load ${key} report.`,
              }));
            }
          })
        )
      );
    };

    fetchAllReports();
  }, []);

  const renderIframe = (key) => {
    if (loading[key]) {
      return <Spin style={{ width: "100%", marginTop: 50 }} size="medium" />;
    }
    if (errors[key]) {
      return <p>Error</p>;
    }

    return (
      <div ref={targetRef} style={{ width: "100%", height: "80vh" }}>
        <iframe
          src={iframeUrls[key]?.url}
          title={key}
          // style={{ width: "100%", height: "500px" }}
          frameBorder={0}
          width={dimensions.width}
          height={dimensions.height}
          allowTransparency
        />
      </div>
    );
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setTimeout(() => {
        if (targetRef.current) {
          setDimensions({
            width:
              dimensions.width !== 0
                ? dimensions.width
                : targetRef.current.offsetWidth,
            height:
              dimensions.height !== 0
                ? dimensions.height
                : targetRef.current.offsetHeight,
          });
        }
      }, 200);
    };

    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [tab]);

  return (
    <Tabs
      onChange={(key) => setTab(key)}
      items={[
        // {
        //   label: t("MERCHANT.BREADCRUMB"),
        //   key: "merchant",
        //   children: renderIframe("merchant"),
        // },
        {
          label: t("BENEFITS"),
          key: "benefits",
          children: renderIframe("benefits"),
        },
        {
          label: t("USERS"),
          key: "users",
          children: renderIframe("users"),
        },
      ]}
    />
  );
};

export default MerchantReports;
