import { useState, useEffect, Fragment } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  DatePicker,
  TimePicker,
  InputNumber,
  Select,
  Divider,
  Popconfirm,
  message,
} from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import {
  Button,
  Micons,
  Modal,
  ModalHeader,
  Radio,
} from "../../../../../components/customAntd";
import { BREADCRUMB_TIER_CREATE_PUSH_NOTIFICATION } from "../../../../../utils/programs/constant";
import NotificationsList from "./notificationsList";
import NotificationPreview from "./notificationPreview";
import { useAppContext } from "../../../../../components/context/app.context";
import { createPushNotification, getAllNotifications } from "../../apiUtils";

import Styles from "../styles/pushNotifications.module.scss";

const { Item } = Form;
const { TextArea } = Input;

const PushNotifications = ({ tier }) => {
  const { currentUserData, localesList } = useAppContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [defaultLocale] = useState("en_US");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [availableLocales, setAvailableLocales] = useState([]);
  const [sentNotifications, setSentNotifications] = useState([]);
  const [scheduledNotifications, setScheduledNotifications] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const watchLocales = Form.useWatch("locales", form);
  const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);
  const type = Form.useWatch("type", form);
  const title = Form.useWatch("title", form);
  const notificationMessage = Form.useWatch("message", form);
  const date = Form.useWatch("date", form);
  const time = Form.useWatch("time", form);

  //Fetching locales
  useEffect(() => {
    if (localesList) {
      const localesData = localesList.map((locale) => ({
        ...locale,
        isSelected: locale.value === defaultLocale,
      }));
      setAvailableLocales(localesData);
    }
  }, [defaultLocale, localesList]);

  //Fetching scheduled notification
  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getAllNotifications(tier.programId, tier.id, {
        filterCondition: {
          status: ["pending"],
        },
      });
      if (response.status !== 200) {
        //error
      } else {
        setScheduledNotifications(response.data);
      }
    };
    fetchNotifications();
  }, [tier]);

  //Fetching Sent notifications
  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getAllNotifications(tier.programId, tier.id, {
        filterCondition: { status: ["send"] },
      });
      if (response.status !== 200) {
        //error
      } else {
        setSentNotifications(response.data);
      }
    };
    fetchNotifications();
  }, [tier]);

  // Updated locales state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  //Set available locales on cancel, close or successfull creation of push notification
  const clearAvailableLocales = () => {
    setAvailableLocales((prev) =>
      prev.map((item) => {
        return { ...item, isSelected: item.value === defaultLocale };
      })
    );
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (!data.locales) delete data.locales;
    if (data.type === "scheduled" || data.type === "repeating") {
      data.notifyTime = dayjs(
        `${dayjs(
          `${dayjs(data.date).format("YYYY-MM-DD")}T${dayjs(data.time).format(
            "HH:mm:ss"
          )}`
        )}`
      ).toDate();
      delete data.date;
      delete data.time;
    }
    const response = await createPushNotification(
      tier.programId,
      tier.id,
      data
    );
    if (response.status !== 200) {
      message.error(
        `Something went wrong while ${
          data.type === "oneTime" ? "sending" : "scheduling"
        } the push notification.`
      );
    } else {
      if (response.data?.type === "oneTime") {
        setSentNotifications((prev) => [{ ...response.data }, ...prev]);
      } else if (response.data?.type === "scheduled") {
        setScheduledNotifications((prev) => [{ ...response.data }, ...prev]);
      }
      setIsOpen(false);
      clearAvailableLocales();
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Button
        type="primary"
        style={{ marginBottom: 30 }}
        onClick={() => setIsOpen(true)}
      >
        <Micons icon="add" isHover={false} />
        {t("PROGRAM_UI.PUSH_NOTIFICATIONS.CREATE_PUSH_NOTIFICATION")}
      </Button>
      <Row gutter={[30, 30]}>
        <Col sm={24} md={12}>
          <NotificationsList
            list={scheduledNotifications}
            setList={setScheduledNotifications}
            isScheduledList
            title={t("PROGRAM_UI.PUSH_NOTIFICATIONS.SCHEDULED_NOTIFICATIONS")}
          />
        </Col>
        <Col sm={24} md={12}>
          <NotificationsList
            list={sentNotifications}
            title={t("PROGRAM_UI.PUSH_NOTIFICATIONS.SENT_NOTIFICATION")}
          />
        </Col>
      </Row>
      <Modal
        width={1024}
        centered
        title={
          <ModalHeader
            title={t("PROGRAM_UI.PUSH_NOTIFICATIONS.CREATE_PUSH_NOTIFICATION")}
            breadcrumbs={BREADCRUMB_TIER_CREATE_PUSH_NOTIFICATION(
              tier?.program?.name,
              tier?.name,
              tier?.programId,
              t
            )}
          />
        }
        open={isOpen}
        footer={[
          <Button
            type="default"
            onClick={() => {
              clearAvailableLocales();
              setIsOpen(false);
            }}
          >
            {t("CANCEL")}
          </Button>,
          <Button
            type="primary"
            loading={isSubmitting}
            onClick={() => form.submit()}
          >
            {type === "oneTime"
              ? t("PROGRAM_UI.PUSH_NOTIFICATIONS.SEND_PUSH_NOTIFICATION")
              : t("PROGRAM_UI.PUSH_NOTIFICATIONS.SCHEDULE_PUSH_NOTIFICATION")}
          </Button>,
        ]}
        destroyOnClose
        onCancel={() => {
          clearAvailableLocales();
          setIsOpen(false);
        }}
        className={Styles["create-push-notification-modal"]}
      >
        <Row gutter={[25]}>
          <Col lg={12}>
            <Form
              form={form}
              onFinish={onSubmit}
              layout="vertical"
              requiredMark={false}
              preserve={false}
              className={Styles["push-notification-form"]}
              disabled={isSubmitting}
            >
              <Item
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE")}:`}
                name="title"
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_VALIDATION"
                    ),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE")}:`}
                name="message"
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_VALIDATION"
                    ),
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.MESSAGE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                label={`${t(
                  "PROGRAM_UI.PUSH_NOTIFICATIONS.DEFAULT_LANGUAGE"
                )}:`}
                name="defaultLocaleId"
                initialValue={defaultLocale}
                rules={[
                  {
                    required: true,
                    message: t(
                      "PROGRAM_UI.PUSH_NOTIFICATIONS.DEFAULT_LANGUAGE_VALIDATION"
                    ),
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  options={availableLocales.map((localEl) => {
                    return {
                      ...localEl,
                      disabled: localEl.isSelected,
                    };
                  })}
                  onChange={(value) =>
                    updateLocales(value, watchDefaultLanguage)
                  }
                  placeholder={t(
                    "PROGRAM_UI.PUSH_NOTIFICATIONS.DEFAULT_LANGUAGE_PLACEHOLDER"
                  )}
                />
              </Item>
              <Item
                name="type"
                label={t("PROGRAM_UI.PUSH_NOTIFICATIONS.WHEN")}
                initialValue="oneTime"
              >
                <Radio.Group style={{ width: "100%" }}>
                  <Row gutter={[20]}>
                    <Col md={12}>
                      <Radio value="oneTime" style={{ width: "100%" }}>
                        {t("PROGRAM_UI.PUSH_NOTIFICATIONS.NOW")}
                      </Radio>
                    </Col>
                    <Col md={12}>
                      <Radio value="scheduled" style={{ width: "100%" }}>
                        {t("PROGRAM_UI.PUSH_NOTIFICATIONS.SCHEDULED")}
                      </Radio>
                    </Col>
                    {/* <Col md={8}>
                      <Radio
                        value="repeating"
                        style={{ width: "100%" }}
                        disabled
                      >
                        Recurring
                      </Radio>
                    </Col> */}
                  </Row>
                </Radio.Group>
              </Item>
              {(type === "scheduled" || type === "repeating") && (
                <Row gutter={[25]}>
                  <Col md={12}>
                    <Item
                      name="date"
                      label={`${t(
                        "PROGRAM_UI.PUSH_NOTIFICATIONS.SCHEDULE_START"
                      )}:`}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        disabledDate={(current) => {
                          return (
                            current < dayjs().subtract(1, "day").endOf("day")
                          );
                        }}
                      />
                    </Item>
                  </Col>
                  <Col md={12}>
                    <Item
                      name="time"
                      label={`${t("PROGRAM_UI.PUSH_NOTIFICATIONS.TIME")}:`}
                    >
                      <TimePicker style={{ width: "100%" }} />
                    </Item>
                  </Col>
                </Row>
              )}
              {type === "repeating" && (
                <>
                  <label>Send push notifications every</label>
                  <Row gutter={[20]}>
                    <Col md={4}>
                      <Item name="period">
                        <InputNumber style={{ width: "100%" }} min={1} />
                      </Item>
                    </Col>
                    <Col md={20}>
                      <Radio.Group style={{ width: "100%" }}>
                        <Row gutter={[20]}>
                          <Col md={8}>
                            <Radio value="oneTime" style={{ width: "100%" }}>
                              Day
                            </Radio>
                          </Col>
                          <Col md={8}>
                            <Radio value="scheduled" style={{ width: "100%" }}>
                              Week
                            </Radio>
                          </Col>
                          <Col md={8}>
                            <Radio value="repeating" style={{ width: "100%" }}>
                              Month
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Col>
                  </Row>
                  <Row gutter={[25]}>
                    <Col md={12}>
                      <Item name="end_date" label="Until:">
                        <DatePicker style={{ width: "100%" }} />
                      </Item>
                    </Col>
                    <Col md={12}>
                      <Item name="end_time" label="Time:">
                        <TimePicker style={{ width: "100%" }} />
                      </Item>
                    </Col>
                  </Row>
                </>
              )}

              <Form.List name="locales">
                {(fields, { add, remove }) => (
                  <div className="languageOptions">
                    <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
                    <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
                    {fields.map(({ key, name }, index) => (
                      <Fragment key={key}>
                        <Item
                          label={`${t("LANGUAGE")}}:`}
                          name={[name, "localeId"]}
                          rules={[
                            {
                              required: true,
                              message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                            },
                          ]}
                        >
                          <Select
                            options={availableLocales.map((localEl) => ({
                              ...localEl,
                              disabled: localEl.isSelected,
                            }))}
                            onChange={(value) =>
                              updateLocales(
                                value,
                                watchLocales[index]?.localeId
                              )
                            }
                            placeholder={t("SELECT")}
                          />
                        </Item>
                        <Item
                          label={`${t("LANGUAGE_OPTIONS.LOCALISED_TITLE")}:`}
                          name={[name, "title"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "LANGUAGE_OPTIONS.LOCALISED_TITLE_ERROR"
                              ),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t(
                              "PROGRAM_UI.PUSH_NOTIFICATIONS.TITLE"
                            )}
                          />
                        </Item>
                        <Item
                          label={`${t(
                            "PROGRAM_UI.PUSH_NOTIFICATIONS.LOCALISED_MESSAGE"
                          )}:`}
                          name={[name, "message"]}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "PROGRAM_UI.PUSH_NOTIFICATIONS.MISSING_LOCALISED_MESSAGE"
                              ),
                              whitespace: true,
                            },
                          ]}
                        >
                          <TextArea
                            placeholder={t(
                              "PROGRAM_UI.PUSH_NOTIFICATIONS.ENTER_MESSAGE"
                            )}
                          />
                        </Item>
                        {fields.length > 0 && (
                          <Divider orientation="center">
                            <Popconfirm
                              title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                              onConfirm={() => {
                                setAvailableLocales((prev) => {
                                  return prev.map((item) =>
                                    item.value === watchLocales[index].localeId
                                      ? {
                                          ...item,
                                          isSelected: false,
                                        }
                                      : item
                                  );
                                });
                                remove(name);
                              }}
                              onCancel={() => {}}
                              okText={t("YES")}
                              cancelText={t("NO")}
                            >
                              <Micons
                                icon="delete"
                                className="deleteIcon"
                                isHover={false}
                                style={{ fontSize: 24, cursor: "pointer" }}
                              />
                            </Popconfirm>
                          </Divider>
                        )}
                      </Fragment>
                    ))}

                    <Button
                      type="primary"
                      className="add-locale-button"
                      onClick={fields.length >= 3 ? () => {} : () => add()}
                      icon={<Micons icon="add" isHover={false} />}
                      disabled={fields.length >= 3}
                    >
                      {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
                    </Button>
                  </div>
                )}
              </Form.List>
            </Form>
          </Col>
          <Col md={12}>
            <NotificationPreview
              title={title}
              message={notificationMessage}
              type={type}
              companyName={currentUserData?.company?.name}
              time={time}
              date={date}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default PushNotifications;
