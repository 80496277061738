import { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  EyeOutlined,
  DeleteOutlined,
  LoadingOutlined,
  UploadOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Form,
  Input,
  Upload,
  message,
  Space,
  Image,
  List,
  Select,
} from "antd";

import { useAppContext } from "../../../../components/context/app.context";
import { Button } from "../../../../components/customAntd";
import CategoryTreeSelect from "../../../../components/categoryTreeSelect";
import GooglePlacesAutoComplete from "../../../../components/GooglePlacesAutoComplete/antPlaces";
import { uploadFiles } from "../../../../utils/common/apiUtils";
import { MerchantAccountType } from "../../../../utils/constants";
import {
  addMerchantToDb,
  updateMerchantDetails,
  updateMerchantToDb,
} from "../apiUtils";
import MerchantLocaleDetails from "../localeDetails";

import Styles from "../styles/merchant.module.scss";

const { Item } = Form;
const { Dragger } = Upload;

const MerchantForm = forwardRef(
  ({ editData, onSubmitCallback, setDisableButtons }, ref) => {
    // Default Images
    const [heroImage, setHeroImage] = useState([]);
    const [logoImage, setLogoImage] = useState([]);
    const [isLogoLoading, setIsLogoLoading] = useState(false);
    const [isLogoPreviewVisible, setLogoPreviewVisible] = useState(false);
    const [isHeroLoading, setIsHeroLoading] = useState(false);
    const [isHeroPreviewVisible, setHeroPreviewVisible] = useState(false);

    //Language Option Images
    const [heroImages, setHeroImages] = useState([]);
    const [logoImages, setLogoImages] = useState([]);
    const [files, setFiles] = useState([]);

    const [languageOptions, setLanguageOptions] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [defaultLanguage] = useState("en_US");

    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
      libraries: ["places"],
    });

    const [form] = Form.useForm();
    const { currentUserData, localesList } = useAppContext();
    const { t } = useTranslation();

    const watchLocation = Form.useWatch("discount_location", form);
    const watchDefaultLanguage = Form.useWatch("defaultLocaleId", form);

    const isEdit = !!editData;
    const isMerchant = currentUserData.type === MerchantAccountType;

    useImperativeHandle(ref, () => ({
      submit: () => {
        // This submit method will be called in the modal
        form.submit();
      },
      resetFormFields: () => {
        form.resetFields();
      },
    }));

    useEffect(() => {
      if (localesList && (editData || currentUserData.merchant)) {
        const { locales, defaultLocaleId } =
          editData ?? currentUserData.merchant;
        setLanguageOptions(
          localesList.map((el) => ({
            ...el,
            isSelected:
              defaultLocaleId === el.value ||
              locales?.find((localeEl) => localeEl.localeId === el.value),
          }))
        );
      } else if (localesList && !(editData || currentUserData.merchant)) {
        setLanguageOptions(
          localesList.map((el) => ({
            ...el,
            isSelected: defaultLanguage === el.value,
          }))
        );
        form.setFieldValue("defaultLocaleId", defaultLanguage);
      }
    }, [localesList, editData, currentUserData]);

    useEffect(() => {
      const record = editData ?? currentUserData?.merchant;
      if (record) {
        const finalData = {
          id: record.id,
          merchant_name: record.name,
          merchant_description: record.description,
          discount_location: record.locations,
          locales: record.locales,
          defaultLocaleId: record.defaultLocaleId,
          merchant_url: record.url,
          merchant_phoneNumber: record.phoneNumber,
          merchant_email: record.email,
          // merchant_selfRedeem: record.selfRedeem ? "yes" : "no",
          type: record.type,
          partnerType: record.partnerType,
          partnerCode: record.partnerCode,
          categoryId: record.categoryId,
        };
        setHeroImage([{ resourceUrl: record.heroImageUrl ?? "null" }]);
        setLogoImage([{ resourceUrl: record.logoUrl ?? "null" }]);
        const heroImagesArray = [];
        const logoImagesArray = [];
        if (record.locales) {
          for (const locale of record?.locales) {
            if (locale?.heroImageUrl)
              heroImagesArray.push([{ resourceUrl: locale?.heroImageUrl }]);
            if (locale?.logoUrl)
              logoImagesArray.push([{ resourceUrl: locale?.logoUrl }]);
          }
          setHeroImages(heroImagesArray);
          setLogoImages(logoImagesArray);
        }
        form.setFieldsValue(finalData);
      }
    }, [currentUserData, editData]);

    useEffect(() => {
      form.setFieldValue("logo_image", logoImage[0]);
      form.setFieldValue("hero_image", heroImage[0]);
    }, [logoImage, heroImage]);

    // Updated locales state if someone changed language in the form
    const updateLocales = (value, prevValue) => {
      setLanguageOptions((prev) => {
        return prev.map((item) =>
          item.value === value
            ? {
                ...item,
                isSelected: true,
              }
            : {
                ...item,
                isSelected: item.value === prevValue ? false : item.isSelected,
              }
        );
      });
    };

    // Change merchant hero image
    const handleHeroImageChange = (data) => {
      setHeroImage([...data]);
      setFiles([...files, ...data]);
    };

    // Change logo image
    const handleLogoImageChange = (data) => {
      setLogoImage([...data]);
      setFiles([...files, ...data]);
    };

    // Handle hero image upload
    const handleBeforeUploadHero = async (file) => {
      const extension = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
      if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
        message.open({
          type: "error",
          content: "Only JPG, JPEG and PNG extensions are allowed",
        });
        form.setFieldValue("hero_image", undefined);
        return Upload.LIST_IGNORE;
      } else if (currentFileIndexOf !== -1) {
        message.open({
          type: "error",
          content: "File name already exists",
        });
        return Upload.LIST_IGNORE;
      } else if (file.size > 10000000) {
        message.open({
          type: "error",
          content: "File size cannot be larger then 10MB.",
        });
        return Upload.LIST_IGNORE;
      } else {
        setIsHeroLoading(true);
        const uploadFile = await uploadFiles({
          fileFor: "benefit",
          isUploadIpfs: false,
          files: file,
        });
        setIsHeroLoading(false);
        if (uploadFile?.status === 200) {
          const uploadArray = [
            {
              name: uploadFile.data.s3Url.split("/").reverse()[0],
              resourceUrl: uploadFile.data.s3Url,
              type: "heroImage",
            },
          ];
          handleHeroImageChange(uploadArray);
        }
        setIsHeroLoading(false);
      }
    };

    //Handle logo image upload
    const handleBeforeUploadLogo = async (file) => {
      const extension = file.name
        .substring(file.name.lastIndexOf(".") + 1)
        .toLowerCase();
      const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
      if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
        message.open({
          type: "error",
          content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
            "FILE_UPLOAD.EXTENSIONS_ALLOWED"
          )}`,
        });
        form.setFieldValue("logo_image", undefined);
        return Upload.LIST_IGNORE;
      } else if (currentFileIndexOf !== -1) {
        message.open({
          type: "error",
          content: t("FILE_UPLOAD.EXISTS"),
        });
        return Upload.LIST_IGNORE;
      } else if (file.size > 10000000) {
        message.open({
          type: "error",
          content: `${t("FILE_UPLOAD.SIZE_ERROR")} 10${t("MB")}`,
        });
        return Upload.LIST_IGNORE;
      } else {
        setIsLogoLoading(true);
        const uploadFile = await uploadFiles({
          fileFor: "benefit",
          isUploadIpfs: false,
          files: file,
        });

        setIsLogoLoading(false);
        if (uploadFile?.status === 200) {
          const uploadArray = [
            {
              name: uploadFile.data.s3Url.split("/").reverse()[0],
              resourceUrl: uploadFile.data.s3Url,
              type: "logoImage",
            },
          ];
          handleLogoImageChange(uploadArray);
        }
        setIsLogoLoading(false);
      }
    };
    // Removing an image from the hero image array using the delete button
    const handleDeleteClickHero = (fileName) => {
      setHeroImage([]);
      form.setFieldValue("hero_image", undefined);
    };

    const handleDeleteClickLogo = (fileName) => {
      setLogoImage([]);
      form.setFieldValue("logo_image", undefined);
    };

    const handleFormSubmit = async (data) => {
      setIsSubmitting(true);
      if (setDisableButtons) setDisableButtons(true);
      data?.locales?.forEach((locale, index) => {
        if (heroImages[index]) {
          locale.heroImageUrl = heroImages?.[index]?.[0]?.resourceUrl;
        } else {
          delete locale.heroImageUrl;
        }
        if (logoImages[index]) {
          locale.logoUrl = logoImages?.[index]?.[0]?.resourceUrl;
        } else {
          delete locale.logoUrl;
        }
      });

      if (data?.locales?.length > 0 && isEdit) {
        data.locales.forEach((locale) => delete locale.merchantId);
      }

      const finalData = {
        [isMerchant ? "merchantName" : "name"]: data.merchant_name,
        [isMerchant ? "merchantEmail" : "email"]: data.merchant_email || null,
        description: data.merchant_description,
        defaultLocaleId: data.defaultLocaleId,
        // selfRedeem: data.merchant_selfRedeem === "yes" ? true : false,
        locales: data.locales,
        locations: data.discount_location?.map((item) => {
          return {
            name: item.name,
            latitude: parseFloat(item.latitude),
            longitude: parseFloat(item.longitude),
          };
        }),
        heroImageUrl: heroImage[0].resourceUrl,
        logoUrl: logoImage[0].resourceUrl,
        phoneNumber: data.merchant_phoneNumber || null,
        url: data.merchant_url || null,
        // Not shown to merchant
        ...(isMerchant
          ? {}
          : {
              type: data.type,
              categoryId: data.categoryId,
              partnerType: data.partnerType,
              partnerCode: data.partnerCode,
            }),
      };

      if (currentUserData.type === "Admin") {
        const response = editData
          ? await updateMerchantToDb(finalData, editData.id)
          : await addMerchantToDb(finalData);
        if (response?.status === 200) {
          setHeroImage([]);
          setLogoImage([]);
          setHeroImages([]);
          setLogoImages([]);
          onSubmitCallback(response);
        } else {
          message.error({
            content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
            key: "error",
            duration: 2,
          });
        }
      } else if (isMerchant) {
        //Call the profiles api here and update the necessary states
        const response = await updateMerchantDetails(finalData);
        if (response?.status === 200) {
          form.setFieldsValue(response.data);
          message.success({
            content: t("MERCHANT.UPDATED_SUCCESSFULLY"),
            key: "success",
            duration: 2,
          });
        } else {
          message.error({
            content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
            key: "error",
            duration: 2,
          });
        }
      }
      setIsSubmitting(false);
      if (setDisableButtons) setDisableButtons(false);
    };

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        disabled={isSubmitting}
        requiredMark={false}
        preserve={false}
      >
        <Row gutter={52}>
          <Col xs={24} md={24}>
            <h3 style={{ marginBottom: 40 }}>{t("MERCHANT.DETAILS")}</h3>

            <Item
              label={`${t("MERCHANT.NAME")} :`}
              name="merchant_name"
              rules={[{ required: true, message: t("MERCHANT.NAME_ERROR") }]}
            >
              <Input placeholder={t("MERCHANT.NAME")} />
            </Item>
            <Item label={`${t("MERCHANT.WEBSITE")} :`} name="merchant_url">
              <Input placeholder={t("MERCHANT.WEBSITE")} />
            </Item>
            <Item
              label={`${t("MERCHANT.PHONE")} :`}
              name="merchant_phoneNumber"
            >
              <Input placeholder={t("MERCHANT.PHONE_PLACEHOLDER")} />
            </Item>
            <Item
              label={`${t("MERCHANT.EMAIL")} :`}
              name="merchant_email"
              rules={[{ type: "email", message: t("MERCHANT.EMAIL_ERROR") }]}
            >
              <Input placeholder={t("EMAIL")} />
            </Item>
            <Row>
              <Item
                label={`${t("MERCHANT.CATEGORY")} :`}
                name="categoryId"
                rules={[
                  {
                    required: true,
                    message: t("MERCHANT.SELECT_CATEGORY"),
                  },
                ]}
                hidden={isMerchant}
              >
                <CategoryTreeSelect name="categoryId" style={{ width: 250 }} />
              </Item>
            </Row>
            <Row>
              <Item
                label={`${t("MERCHANT.TYPE")} :`}
                name="type"
                rules={[
                  {
                    required: true,
                    message: t("MERCHANT.SELECT_TYPE"),
                  },
                ]}
                hidden={isMerchant}
              >
                <Select
                  style={{ width: 200 }}
                  className="mr-2"
                  placeholder={t("MERCHANT.SELECT_TYPE")}
                  options={[
                    {
                      value: "retailMerchant",
                      label: t("MERCHANT.RETAIL"),
                    },
                    {
                      value: "artist",
                      label: t("MERCHANT.ARTIST"),
                    },
                  ]}
                />
              </Item>
            </Row>
            <Item label={`${t("MERCHANT.DESC")} :`} name="merchant_description">
              <MDEditor
                overflow={false}
                placeholder={t("ADD_DESCRIPTION")}
                previewOptions={{
                  rehypePlugins: [[rehypeSanitize]],
                }}
              />
              {/* <Input placeholder={t("ADD_DESCRIPTION")} /> */}
            </Item>

            <Item label={`${t("LOCATION.TITLE")} :`}>
              <Form.List name="discount_location">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, value, ...restField }, index) => (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "name"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_NAME"),
                            },
                          ]}
                        >
                          {isLoaded && (
                            <GooglePlacesAutoComplete
                              index={index}
                              form={form}
                              defaultValue={
                                watchLocation && watchLocation[index]?.name
                              }
                            />
                          )}
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "latitude"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_LAT"),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("LOCATION.LAT")}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "longitude"]}
                          rules={[
                            {
                              required: true,
                              message: t("LOCATION.MISSING_LONG"),
                            },
                          ]}
                        >
                          <Input
                            disabled={true}
                            placeholder={t("LOCATION.LONG")}
                          />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={() => add()}
                        icon={<PlusOutlined />}
                      >
                        {t("LOCATION.ADD")}
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Item>
            <Item
              label={`${t("DEFAULT_LANGUAGE")}:`}
              name="defaultLocaleId"
              rules={[
                {
                  required: true,
                  message: t("DEFAULT_LANGUAGE_ERROR"),
                },
              ]}
            >
              <Select
                style={{ width: 300 }}
                options={languageOptions.map((el) => ({
                  ...el,
                  disabled: el.isSelected,
                }))}
                onChange={(value) => updateLocales(value, watchDefaultLanguage)}
                placeholder={t("SELECT_DEFAULT_LANGUAGE")}
              />
            </Item>
            <Item
              label={`${t("HERO_IMAGE")} :`}
              name="hero_image"
              extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                "MAX_SIZE"
              )}: 10${t("MB")}`}
              rules={[
                {
                  required: isEdit && heroImage.length !== 0 ? false : true,
                  message: t("UPLOAD_HERO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false} //Set to false
                beforeUpload={handleBeforeUploadHero} //Check image validations
                disabled={isHeroLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={heroImage}
                maxCount={1}
              >
                {isHeroLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {heroImage.length !== 0 && (
              <Item label={`${t("HERO_IMAGE")} :`}>
                <List
                  dataSource={heroImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickHero(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setHeroPreviewVisible(!isHeroPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("HERO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}

            {heroImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={heroImage[0].resourceUrl}
                preview={{
                  visible: isHeroPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setHeroPreviewVisible(visible),
                }}
              />
            )}
            <Item
              label={`${t("LOGO_IMAGE")} :`}
              name="logo_image"
              extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                "MAX_SIZE"
              )}: 10${t("MB")}`}
              rules={[
                {
                  required: isEdit && files.length !== 0 ? false : true,
                  message: t("UPLOAD_LOGO_IMAGE"),
                },
              ]}
            >
              <Dragger
                showUploadList={false}
                beforeUpload={handleBeforeUploadLogo}
                disabled={isLogoLoading}
                accept="image/jpg, image/jpeg, image/png"
                customRequest={() => null}
                fileList={logoImage}
                maxCount={1}
              >
                {isLogoLoading ? (
                  <>
                    <LoadingOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{`${t("UPLOADING")}...`}</p>
                  </>
                ) : (
                  <>
                    <UploadOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                    <p className="ant-upload-hint">{t("UPLOAD")}</p>
                  </>
                )}
              </Dragger>
            </Item>
            {logoImage.length !== 0 && (
              <Item label={`${t("LOGO_IMAGE")} :`}>
                <List
                  dataSource={logoImage}
                  className="upload-list"
                  style={{ marginBottom: 20 }}
                  renderItem={(item, index) => (
                    <List.Item
                      actions={[
                        <DeleteOutlined
                          onClick={() => handleDeleteClickLogo(item.name)}
                        />,
                        <EyeOutlined
                          onClick={() =>
                            setLogoPreviewVisible(!isLogoPreviewVisible)
                          }
                        />,
                      ]}
                    >
                      <div>
                        <span className="item-index">{index + 1}.</span>{" "}
                        <span className="item-name">
                          {item.name ?? t("LOGO_IMAGE_FILE")}
                        </span>
                      </div>
                    </List.Item>
                  )}
                />
              </Item>
            )}

            {logoImage[0]?.resourceUrl && (
              <Image
                className={Styles.imagePreview}
                src={logoImage[0].resourceUrl}
                preview={{
                  visible: isLogoPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setLogoPreviewVisible(visible),
                }}
              />
            )}
            {/* <Item
        label={`${t("MERCHANT.SELF_REDEEM")}:`}
        name="merchant_selfRedeem"
      >
        <Radio.Group style={{ width: "100%" }} defaultValue="no">
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="yes">
                {t("YES")}
              </Radio>
            </Col>
            <Col xs={24} md={12}>
              <Radio style={{ width: "100%" }} value="no">
                {t("NO")}
              </Radio>
            </Col>
          </Row>
        </Radio.Group>
      </Item> */}
            <Item
              label={`${t("MERCHANT.PARTNER_CODE")} :`}
              name="partnerCode"
              rules={[
                { required: true, message: t("MERCHANT.PARTNER_CODE_ERROR") },
              ]}
              hidden={isMerchant}
            >
              <Input placeholder={t("MERCHANT.PARTNER_CODE")} />
            </Item>
            <Item
              label={`${t("MERCHANT.PARTNER_TYPE")} :`}
              name="partnerType"
              rules={[
                {
                  required: true,
                  message: t("MERCHANT.SELECT_PARTNER_TYPE"),
                },
              ]}
              hidden={isMerchant}
            >
              <Select
                style={{ width: 200 }}
                className="mr-2"
                placeholder={t("MERCHANT.SELECT_PARTNER_TYPE")}
                options={[
                  {
                    value: "Merchant",
                    label: t("MERCHANT.BREADCRUMB"),
                  },
                  {
                    value: "Affiliate",
                    label: t("MERCHANT.AFFILIATE"),
                  },
                  {
                    value: "Franchisee",
                    label: t("MERCHANT.FRANCHISEE"),
                  },
                ]}
              />
            </Item>
          </Col>
        </Row>
        <MerchantLocaleDetails
          languageOptions={languageOptions}
          onLocaleSelect={updateLocales}
          heroImages={heroImages}
          setHeroImages={setHeroImages}
          logoImages={logoImages}
          setLogoImages={setLogoImages}
        />
        {isMerchant && (
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: 30 }}
            loading={isSubmitting}
          >
            {t("UPDATE")}
          </Button>
        )}
      </Form>
    );
  }
);

export default MerchantForm;
