import { useState } from "react";
import {
  Form,
  Select,
  Input,
  Upload,
  Divider,
  message,
  Popconfirm,
  Image,
  List,
} from "antd";
import MDEditor from "@uiw/react-md-editor";
import rehypeSanitize from "rehype-sanitize";
import {
  PlusOutlined,
  DeleteOutlined,
  EyeOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/customAntd/";
import { uploadFiles } from "../../../../utils/common/apiUtils";

import Styles from "../styles/localeDetails.module.scss";

const { Item } = Form;
const { Dragger } = Upload;

const MerchantLocaleDetails = ({
  languageOptions,
  onLocaleSelect,
  heroImages,
  setHeroImages,
  logoImages,
  setLogoImages,
}) => {
  const [files, setFiles] = useState([]);
  const [isHeroLoading, setIsHeroLoading] = useState([]);
  const [isHeroPreviewVisible, setIsHeroPreviewVisible] = useState([]);
  const [isLogoLoading, setIsLogoLoading] = useState([]);
  const [isLogoPreviewVisible, setIsLogoPreviewVisible] = useState([]);
  const { t } = useTranslation();

  const form = Form.useFormInstance();
  const watchLocales = Form.useWatch("locales", form);

  const handleImageChange = (data, index, type) => {
    if (type === "heroImage") {
      setHeroImages((prev) => {
        prev[index] = [...data];
        return [...prev];
      });
    } else {
      setLogoImages((prev) => {
        prev[index] = [...data];
        return [...prev];
      });
    }
    setFiles([...files, ...data]);
  };

  //Handle hero image upload
  const handleBeforeUpload = async (file, index, type) => {
    const extension = file.name
      .substring(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const currentFileIndexOf = files.findIndex((i) => i.name === file.name);
    if (extension !== "jpg" && extension !== "jpeg" && extension !== "png") {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.ONLY")} JPG, JPEG and PNG ${t(
          "FILE_UPLOAD.EXTENSIONS_ALLOWED"
        )}`,
      });
      return Upload.LIST_IGNORE;
    } else if (currentFileIndexOf !== -1) {
      message.open({
        type: "error",
        content: t("FILE_UPLOAD.EXISTS"),
      });
      return Upload.LIST_IGNORE;
    } else if (file.size > 10000000) {
      message.open({
        type: "error",
        content: `${t("FILE_UPLOAD.SIZE_ERROR")} 10${t("MB")}`,
      });
      return Upload.LIST_IGNORE;
    } else {
      if (type === "heroImage") {
        setIsHeroLoading((prev) => {
          prev[index] = true;
          return [...prev];
        });
      } else {
        setIsLogoLoading((prev) => {
          prev[index] = true;
          return [...prev];
        });
      }
      const uploadFile = await uploadFiles({
        fileFor: "benefit",
        isUploadIpfs: false,
        files: file,
      });
      if (type === "heroImage") {
        setIsHeroLoading((prev) => {
          prev[index] = false;
          return [...prev];
        });
      } else {
        setIsLogoLoading((prev) => {
          prev[index] = false;
          return [...prev];
        });
      }
      if (uploadFile?.status === 200) {
        const uploadArray = [
          {
            name: uploadFile.data.s3Url.split("/").reverse()[0],
            resourceUrl: uploadFile.data.s3Url,
            type: type,
          },
        ];
        // handleHeroImageChange(uploadArray, index);
        handleImageChange(uploadArray, index, type);
      }

      setIsHeroLoading((prev) => {
        const copy = [...prev];
        copy[index] = false;
        return copy;
      });
    }
  };

  const handleDeleteClickHero = (index) => {
    setHeroImages((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
    setIsHeroPreviewVisible((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
  };

  const handleDeleteClickLogo = (index) => {
    setLogoImages((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
    setIsLogoPreviewVisible((prev) => {
      prev[index] = undefined;
      return [...prev];
    });
  };
  
  return (
    <>
      <Form.List name="locales">
        {(fieldItems, { add, remove }) => {
          return (
            <div className="languageOptions">
              <h3 className={`heading ${Styles["form-heading"]}`}>{t("LANGUAGE_OPTIONS.HEADING")}</h3>
              <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
              {fieldItems.map((fieldItemEl, index) => {
                return (
                  <div key={fieldItemEl.key}>
                    <Item
                      name={[index, "localeId"]}
                      label={`${t("LANGUAGE")} :`}
                    >
                      <Select
                        placeholder={t("SELECT")}
                        options={languageOptions.map((localEl) => ({
                          ...localEl,
                          disabled: localEl.isSelected,
                        }))}
                        onChange={(value) =>
                          onLocaleSelect(value, watchLocales[index].localeId)
                        }
                        style={{ width: 300 }}
                      />
                    </Item>
                    <Item
                      label={`${t("MERCHANT.NAME")} :`}
                      name={[index, "name"]}
                      rules={[
                        {
                          required: true,
                          message: t("LANGUAGE_OPTIONS.ENTER_NAME"),
                        },
                      ]}
                    >
                      <Input placeholder={t("ADD_NAME")} />
                    </Item>
                    <Item
                      label={`${t("MERCHANT.DESC")} :`}
                      name={[index, "description"]}
                    >
                      <MDEditor
                        overflow={false}
                        placeholder={t("ADD_DESCRIPTION")}
                        previewOptions={{
                          rehypePlugins: [[rehypeSanitize]],
                        }}
                      />
                      {/* <Input placeholder={t("ADD_DESCRIPTION")} /> */}
                    </Item>
                    <Item
                      label={`${t("HERO_IMAGE")} :`}
                      extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                        "MAX_SIZE"
                      )}: 10${t("MB")}`}
                    >
                      <Dragger
                        showUploadList={false}
                        beforeUpload={(file) =>
                          handleBeforeUpload(file, index, "heroImage")
                        }
                        disabled={isHeroLoading[index]}
                        accept="image/jpg, image/jpeg, image/png"
                        customRequest={() => null}
                        fileList={heroImages[index]}
                        maxCount={1}
                      >
                        {isHeroLoading[index] ? (
                          <>
                            <LoadingOutlined
                              style={{ color: "rgba(0, 0, 0, 0.25)" }}
                            />
                            <p className="ant-upload-hint">{`${t(
                              "UPLOADING"
                            )}...`}</p>
                          </>
                        ) : (
                          <>
                            <UploadOutlined
                              style={{ color: "rgba(0, 0, 0, 0.25)" }}
                            />
                            <p className="ant-upload-hint">{t("UPLOAD")}</p>
                          </>
                        )}
                      </Dragger>
                    </Item>
                    {heroImages[index]?.length > 0 && (
                      <Item label={`${t("HERO_IMAGE")} :`}>
                        <List
                          dataSource={heroImages[index]}
                          className="upload-list"
                          style={{ marginBottom: 20 }}
                          renderItem={(item, itemIndex) => (
                            <List.Item
                              actions={[
                                <DeleteOutlined
                                  onClick={() =>
                                    // setFieldValue()
                                    handleDeleteClickHero(index)
                                  }
                                />,
                                <EyeOutlined
                                  onClick={() =>
                                    setIsHeroPreviewVisible((prev) => {
                                      prev[index] = true;
                                      return [...prev];
                                    })
                                  }
                                />,
                              ]}
                            >
                              <div>
                                <span className="item-index">
                                  {itemIndex + 1}.
                                </span>{" "}
                                <span className="item-name">
                                  {item.name ?? t("HERO_IMAGE_FILE")}
                                </span>
                              </div>
                            </List.Item>
                          )}
                        />
                      </Item>
                    )}

                    {heroImages?.[index]?.[0]?.resourceUrl &&
                      isHeroPreviewVisible[index] && (
                        <Image
                          className={Styles.imagePreview}
                          src={heroImages[index][0]?.resourceUrl}
                          preview={{
                            visible: isHeroPreviewVisible[index],
                            onVisibleChange: (visible, prevVisible) =>
                              setIsHeroPreviewVisible((prev) => {
                                prev[index] = visible;
                                return [...prev];
                              }),
                          }}
                        />
                      )}
                    <Item
                      label={`${t("LOGO_IMAGE")} :`}
                      extra={`${t("FILE_TYPES_SUPPORTED")}: JPG, JPEG, PNG ${t(
                        "MAX_SIZE"
                      )}: 10${t("MB")}`}
                    >
                      <Dragger
                        showUploadList={false}
                        beforeUpload={(file) =>
                          handleBeforeUpload(file, index, "logoImage")
                        }
                        disabled={isLogoLoading[index]}
                        accept="image/jpg, image/jpeg, image/png"
                        customRequest={() => null}
                        fileList={logoImages?.[index]}
                        maxCount={1}
                      >
                        {isLogoLoading[index] ? (
                          <>
                            <LoadingOutlined
                              style={{ color: "rgba(0, 0, 0, 0.25)" }}
                            />
                            <p className="ant-upload-hint">{`${t(
                              "UPLOADING"
                            )}...`}</p>
                          </>
                        ) : (
                          <>
                            <UploadOutlined
                              style={{ color: "rgba(0, 0, 0, 0.25)" }}
                            />
                            <p className="ant-upload-hint">{t("UPLOAD")}</p>
                          </>
                        )}
                      </Dragger>
                    </Item>
                    {logoImages?.[index]?.length > 0 && (
                      <Item label={`${t("LOGO_IMAGE")} :`}>
                        <List
                          dataSource={logoImages?.[index]}
                          className="upload-list"
                          style={{ marginBottom: 20 }}
                          renderItem={(item, itemIndex) => (
                            <List.Item
                              actions={[
                                <DeleteOutlined
                                  onClick={() =>
                                    // setFieldValue()
                                    handleDeleteClickLogo(index)
                                  }
                                />,
                                <EyeOutlined
                                  onClick={() =>
                                    setIsLogoPreviewVisible((prev) => {
                                      prev[index] = true;
                                      return [...prev];
                                    })
                                  }
                                />,
                              ]}
                            >
                              <div>
                                <span className="item-index">
                                  {itemIndex + 1}.
                                </span>{" "}
                                <span className="item-name">
                                  {item.name ?? t("LOGO_IMAGE_FILE")}
                                </span>
                              </div>
                            </List.Item>
                          )}
                        />
                      </Item>
                    )}

                    {!!logoImages?.[index]?.[0]?.resourceUrl &&
                      isLogoPreviewVisible?.[index] && (
                        <Image
                          className={Styles.imagePreview}
                          src={logoImages[index][0]?.resourceUrl}
                          preview={{
                            visible: isLogoPreviewVisible[index],
                            onVisibleChange: (visible, prevVisible) =>
                              setIsLogoPreviewVisible((prev) => {
                                prev[index] = visible;
                                return [...prev];
                              }),
                          }}
                        />
                      )}

                    {fieldItems.length > 0 && (
                      <Divider orientation="center">
                        <Popconfirm
                          title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                          disabled={
                            isHeroLoading[index] || isLogoLoading[index]
                          }
                          onConfirm={() => {
                            setHeroImages((prev) => {
                              prev.splice(index, 1);
                              return [...prev];
                            });
                            setIsHeroPreviewVisible((prev) => {
                              prev.splice(index, 1);
                              return [...prev];
                            });
                            remove(index);
                          }}
                          onCancel={() => {}}
                          okText={t("YES")}
                          cancelText={t("NO")}
                        >
                          <DeleteOutlined
                            className="deleteIcon"
                            style={{ fontSize: 24 }}
                          />
                        </Popconfirm>
                      </Divider>
                    )}
                  </div>
                );
              })}
              <Button
                className="add-locale-button"
                type="primary"
                onClick={() => {
                  add({});
                }}
                // disabled={!canAdd}
              >
                <PlusOutlined /> {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
              </Button>
            </div>
          );
        }}
      </Form.List>
    </>
  );
};

export default MerchantLocaleDetails;
