import { Fragment, useEffect, useState, useMemo } from "react";
import { Form, Input, Row, Col, Select, Divider, Popconfirm } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { t } from "i18next";

import { Button } from "../../../../../components/customAntd";
import { useAppContext } from "../../../../../components/context/app.context";

import Styles from "./styles/step2.module.scss";

const { Item } = Form;

const Step2 = ({ form, isEdit, editData, defaultLocaleId }) => {
  const [defaultLocale] = useState(defaultLocaleId);
  const [availableLocales, setAvailableLocales] = useState([]);
  const watchLocales = Form.useWatch("surveyQuestionLocales", form);
  const watchQuestions = Form.useWatch("surveyQuestions", form);

  const { localesList } = useAppContext();

  const AnswerTypes = useMemo(
    () => [
      {
        label: t("SURVEY.SINGLE_SELECT"),
        value: "SingleSelect",
      },
      {
        label: t("SURVEY.MULTI_SELECT"),
        value: "MultiSelect",
      },
      {
        label: t("SURVEY.RADIO"),
        value: "Radio",
      },
      {
        label: t("SURVEY.CHECKBOX"),
        value: "Checkbox",
      },
    ],
    []
  );

  useEffect(() => {
    if (isEdit) {
      const surveyQuestions = [];
      const surveyQuestionLocales = [];
      editData.surveyQuestions.forEach((questionEl, index) => {
        surveyQuestions.push({
          question: questionEl.question,
          answerType: questionEl.answerType,
          options: questionEl.options.map((option) => ({ option })),
        });
        if (questionEl?.locales?.length > 0) {
          let localeIndex = 0;
          const existingLocale = surveyQuestionLocales.filter((localeEl) => {
            return !!questionEl.locales.find((locale, index) => {
              if (locale.localeId === localeEl.localeId) {
                localeIndex = index;
                return true;
              } else {
                return false;
              }
            });
          })[0];

          if (existingLocale) {
            existingLocale.questions.push({
              question: questionEl.locales[localeIndex].question,
              options: questionEl.locales[localeIndex].options.map(
                (option) => ({
                  option,
                })
              ),
            });
          } else {
            surveyQuestionLocales.push({
              localeId: questionEl.locales[localeIndex].localeId,
              questions: [
                {
                  question: questionEl.locales[localeIndex].question,
                  options: questionEl.locales[localeIndex].options.map(
                    (option) => ({
                      option,
                    })
                  ),
                },
              ],
            });
          }
        }
      });
      form.setFieldsValue({ surveyQuestions, surveyQuestionLocales });
    }
  }, [isEdit]);

  useEffect(() => {
    if (localesList) {
      const localesData = localesList.map((locale) => ({
        ...locale,
        isSelected: isEdit
          ? locale.value === editData.defaultLocaleId ||
            !!editData?.surveyQuestions.find(({ locales }) =>
              locales.find((localeEl) => localeEl.localeId === locale.value)
            )
          : locale.value === defaultLocale,
      }));
      setAvailableLocales(localesData);
    }
  }, [defaultLocale, localesList, isEdit, editData]);

  // Updated locals state if someone changed language in the form
  const updateLocales = (value, prevValue) => {
    setAvailableLocales((prev) => {
      return prev.map((item) =>
        item.value === value
          ? {
              ...item,
              isSelected: true,
            }
          : {
              ...item,
              isSelected: item.value === prevValue ? false : item.isSelected,
            }
      );
    });
  };

  return (
    <div className={Styles.container}>
      <h3 className={Styles.heading}>{t("SURVEY.QUESTION_HEADING")}</h3>
      <p className={Styles.info}>{t("SURVEY.QUESTION_DESCRIPTION")}</p>
      <Form.List name="surveyQuestions">
        {(questionFields, { add, remove }) => (
          <Fragment>
            {questionFields.map(({ name }, index) => {
              return (
                <>
                  <Row gutter={[25, 25]}>
                    <Col lg={24}>
                      <Item
                        label={`${t("SURVEY.QUESTION")} ${index + 1}:`}
                        name={[name, "question"]}
                        rules={[
                          {
                            required: true,
                            message: t("SURVEY.QUESTION_ERROR"),
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input placeholder={t("SURVEY.QUESTION_PLACEHOLDER")} />
                      </Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={8}>
                      <Item
                        label={t("SURVEY.ANSWER_TYPE")}
                        name={[name, "answerType"]}
                        rules={[
                          {
                            required: true,
                            message: t("SURVEY.ANSWER_TYPE_ERROR"),
                            whitespace: true,
                          },
                        ]}
                      >
                        <Select
                          options={AnswerTypes}
                          placeholder={t("SELECT")}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Form.List name={[name, "options"]}>
                    {(optionFields, { add, remove }) => (
                      <div>
                        {optionFields.map(
                          ({ name: innerName }, optionIndex) => (
                            <Row gutter={[25, 25]} type="flex" align="middle">
                              <Col lg={23}>
                                <Item
                                  label={`${t("SURVEY.OPTIONS")} ${
                                    optionIndex + 1
                                  }:`}
                                  name={[innerName, "option"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("SURVEY.OPTIONS_ERROR"),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={t(
                                      "SURVEY.OPTIONS_PLACEHOLDER"
                                    )}
                                  />
                                </Item>
                              </Col>
                              <Col lg={1}>
                                <Popconfirm
                                  title={t("SURVEY.CONFIRM_DELETE_OPTION")}
                                  onConfirm={() => {
                                    remove(index);
                                    if (watchLocales?.length > 0) {
                                      form.setFieldValue(
                                        "surveyQuestionLocales",
                                        watchLocales.map((localeEl) => ({
                                          ...localeEl,
                                          questions: localeEl.questions.map(
                                            (question, qElIndex) => {
                                              const optionsArray =
                                                question.options;
                                              if (qElIndex === index) {
                                                optionsArray.splice(index, 1);
                                              }
                                              return {
                                                ...question,
                                                options: optionsArray,
                                              };
                                            }
                                          ),
                                        }))
                                      );
                                    }
                                  }}
                                  onCancel={() => {}}
                                  okText={t("YES")}
                                  cancelText={t("NO")}
                                >
                                  <DeleteOutlined
                                    className="deleteIcon"
                                    style={{
                                      fontSize: 22,
                                      color: "red",
                                      marginTop: 5,
                                    }}
                                  />
                                </Popconfirm>
                              </Col>
                            </Row>
                          )
                        )}

                        <Button
                          type="primary"
                          className="add-locale-button"
                          onClick={() => {
                            if (watchLocales?.length > 0) {
                              form.setFieldValue(
                                "surveyQuestionLocales",
                                watchLocales.map((localeEl) => {
                                  return {
                                    ...localeEl,
                                    questions: localeEl.questions.map(
                                      (questionEl, elIndex) => {
                                        return {
                                          ...questionEl,
                                          options:
                                            elIndex === index
                                              ? questionEl?.options
                                                ? [...questionEl?.options, {}]
                                                : [{}]
                                              : questionEl?.options,
                                        };
                                      }
                                    ),
                                  };
                                })
                              );
                            }
                            add();
                          }}
                          icon={<PlusOutlined />}
                          disabled={optionFields.length === 4}
                        >
                          {t("SURVEY.OPTIONS_ADD")}
                        </Button>
                      </div>
                    )}
                  </Form.List>
                  {questionFields.length > 0 && (
                    <Divider orientation="center">
                      <Popconfirm
                        title={t("SURVEY.CONFIRM_DELETE_QUESTION")}
                        onConfirm={() => {
                          remove(name);
                          if (questionFields.length === 1) {
                            form.setFieldValue("surveyQuestionLocales", []);
                            setAvailableLocales((prev) => {
                              return prev.map((item) =>
                                item.value === watchLocales[0].localeId
                                  ? {
                                      ...item,
                                      isSelected: false,
                                    }
                                  : item
                              );
                            });
                          } else if (watchLocales?.length > 0) {
                            form.setFieldValue(
                              "surveyQuestionLocales",
                              watchLocales.map((localeEl) => {
                                const questionsArray = [...localeEl.questions];
                                questionsArray.splice(index, 1);
                                return {
                                  ...localeEl,
                                  questions: questionsArray,
                                };
                              })
                            );
                          }
                        }}
                        onCancel={() => {}}
                        okText={t("YES")}
                        cancelText={t("NO")}
                      >
                        <DeleteOutlined
                          className="deleteIcon"
                          style={{ fontSize: 24 }}
                        />
                      </Popconfirm>
                    </Divider>
                  )}
                </>
              );
            })}
            <Button
              type="primary"
              className="add-locale-button"
              onClick={() => {
                if (watchLocales?.length > 0) {
                  form.setFieldValue(
                    "surveyQuestionLocales",
                    watchLocales.map((localeEl) => ({
                      ...localeEl,
                      questions: [...localeEl.questions, {}],
                    }))
                  );
                }
                add();
              }}
              icon={<PlusOutlined />}
            >
              {t("SURVEY.ADD_NEW_QUESTION")}
            </Button>
          </Fragment>
        )}
      </Form.List>
      <Form.List name="surveyQuestionLocales">
        {(questionsLocales, { add, remove }) => (
          <div className="languageOptions">
            <h3 className="heading">{t("LANGUAGE_OPTIONS.HEADING")}</h3>
            <p className="info">{t("LANGUAGE_OPTIONS.DESCRIPTION")}</p>
            {questionsLocales.map(({ name }, index) => {
              return (
                <>
                  <Row>
                    <Col lg={6}>
                      <Item
                        label={`${t("LANGUAGE")} :`}
                        name={[name, "localeId"]}
                        rules={[
                          {
                            required: true,
                            message: t("LANGUAGE_OPTIONS.SELECT_LANGUAGE"),
                          },
                        ]}
                      >
                        <Select
                          options={availableLocales.map((localEl) => {
                            return {
                              ...localEl,
                              disabled: localEl.isSelected,
                            };
                          })}
                          onChange={(value) => {
                            updateLocales(value, watchLocales[index].localeId);
                          }}
                          placeholder={t("SELECT")}
                          getPopupContainer={() => document.body}
                        />
                      </Item>
                    </Col>
                  </Row>
                  <Form.List name={[name, "questions"]}>
                    {(fields) => (
                      <>
                        {fields.map(({ key, name }, questionIndex) => {
                          return (
                            <Row gutter={[25, 25]}>
                              <Col lg={24}>
                                <Item
                                  label={`${t("SURVEY.QUESTION")} ${
                                    questionIndex + 1
                                  }:`}
                                  name={[name, "question"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("SURVEY.QUESTION_ERROR"),
                                      whitespace: true,
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={
                                      watchQuestions[questionIndex]?.question ||
                                      t("SURVEY.QUESTION_PLACEHOLDER")
                                    }
                                  />
                                </Item>
                                <Form.List name={[name, "options"]}>
                                  {(optionFields) => (
                                    <div>
                                      {optionFields.map(
                                        ({ key, name }, index) => (
                                          <Fragment key={key}>
                                            <Row gutter={[25, 25]}>
                                              <Col lg={24}>
                                                <Item
                                                  label={`${t(
                                                    "SURVEY.OPTIONS"
                                                  )} ${index + 1}:`}
                                                  name={[name, "option"]}
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: t(
                                                        "SURVEY.OPTIONS_ERROR"
                                                      ),
                                                    },
                                                  ]}
                                                >
                                                  <Input
                                                    placeholder={
                                                      watchQuestions[
                                                        questionIndex
                                                      ]?.options[index]
                                                        ?.option ||
                                                      t(
                                                        "SURVEY.OPTIONS_PLACEHOLDER"
                                                      )
                                                    }
                                                  />
                                                </Item>
                                              </Col>
                                            </Row>
                                          </Fragment>
                                        )
                                      )}
                                    </div>
                                  )}
                                </Form.List>
                              </Col>
                              {questionIndex !== fields.length - 1 && (
                                <Divider
                                  type="horizontal"
                                  style={{ marginTop: 0 }}
                                />
                              )}
                            </Row>
                          );
                        })}
                      </>
                    )}
                  </Form.List>
                  {questionsLocales.length > 0 && (
                    <Divider orientation="center">
                      <Popconfirm
                        title={t("LANGUAGE_OPTIONS.CONFIRM_DELETE")}
                        onConfirm={() => {
                          remove(index);
                          setAvailableLocales((prev) => {
                            return prev.map((item) =>
                              item.value === watchLocales[index].localeId
                                ? {
                                    ...item,
                                    isSelected: false,
                                  }
                                : item
                            );
                          });
                          remove(name);
                        }}
                        onCancel={() => {}}
                        okText={t("YES")}
                        cancelText={t("NO")}
                      >
                        <DeleteOutlined
                          className="deleteIcon"
                          style={{ fontSize: 24 }}
                        />
                      </Popconfirm>
                    </Divider>
                  )}
                </>
              );
            })}

            <Button
              type="primary"
              className="add-locale-button"
              onClick={() => {
                add({
                  questions: form
                    .getFieldsValue()
                    .surveyQuestions.map((question) => {
                      return {
                        options: question?.options?.map(() => ({})),
                      };
                    }),
                });
              }}
              icon={<PlusOutlined />}
              disabled={questionsLocales.length >= 3}
            >
              {t("LANGUAGE_OPTIONS.ADD_NEW_LOCALE")}
            </Button>
          </div>
        )}
      </Form.List>
    </div>
  );
};

export default Step2;
