import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Flex, Form, Tooltip, Input } from "antd";
import dayjs from "dayjs";

import {
  Button,
  Micons,
  Modal,
  ModalHeader,
  Table,
} from "../../../../components/customAntd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce } from "../../../../utils/helper";
import { createUser, updtaeUser } from "../../../profile/apiUtils";
import { useAppContext } from "../../../../components/context/app.context";
import { getUsers } from "../../../../redux/user.slice";

import styles from "./manageUser.module.scss";
import { getRoleTree } from "../../../../redux/role.slice";
import {
  BREADCRUMB_MERCHANT_CREATE,
  BREADCRUMB_MERCHANT_MANAGE_USERS,
} from "../../../../utils/merchants/constant";

const { Item } = Form;

const ManageUsers = ({ merchantDetails, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [userList, setUserList] = useState();
  const [showUserForm, setShowUserForm] = useState(null);
  const [isFetchingUsers, setIsFetchingUsers] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [merchantRoleId, setMerchantRoleId] = useState(null);
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });

  const { currentUserData } = useAppContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  //Get all roles
  useEffect(() => {
    if (currentUserData) {
      const currentRoleId = currentUserData.roles.find(
        (el) => el.name === "Admin"
      ).id;
      (async () => {
        dispatch(getRoleTree({ currentRoleId })).then((res) => {
          setMerchantRoleId(
            res?.payload?.children?.find((el) => el.name === "Merchant").id
          );
        });
      })();
    }
  }, [currentUserData]);

  //Fetch all users for the merchant
  useEffect(() => {
    if (merchantDetails) {
      (async function () {
        setIsFetchingUsers(true);
        const response = await dispatch(
          getUsers({ merchant_id: merchantDetails.id })
        );
        if (response.type === "user/getUsers/fulfilled") {
          setUserList(response.payload);
          setIsFetchingUsers(false);
        }
      })();
    }
  }, [merchantDetails]);

  //table columns
  const columns = useMemo(
    () => [
      {
        key: t("NAME"),
        dataIndex: "name",
        title: "Name",
      },
      {
        key: t("EMAIL"),
        dataIndex: "email",
        title: "Email",
      },
      {
        key: "createdAt",
        dataIndex: "createdAt",
        title: t("CREATED_DATE"),
        render: (_, record) => {
          return <p>{dayjs(record.createAt).format("MM-DD-YYYY")}</p>;
        },
      },
      {
        title: t("ACTION"),
        key: "action",
        width: "15%",
        render: (_, record) => (
          <Button type="link">
            <Tooltip title={t("EDIT")}>
              <Micons
                icon="edit"
                className="table-action-icon"
                onClick={() => setFormData(record)}
              />
            </Tooltip>
          </Button>
        ),
      },
    ],
    [t]
  );

  const setFormData = (record) => {
    if (record) {
      setShowUserForm("edit");
      form.setFieldsValue(record);
    } else {
      setShowUserForm("create");
    }
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (showUserForm === "create") {
      const response = await createUser(
        data.name,
        data.email,
        null,
        [merchantRoleId],
        null,
        "Merchant",
        merchantDetails.id
      );
      if (response.status === 200) {
        setUserList((prev) => [response.data, ...prev]);
        setShowUserForm(null);
      }
    } else {
      const response = await updtaeUser(
        data.id,
        data.name,
        data.email,
        null,
        [merchantRoleId],
        null,
        "Merchant",
        merchantDetails.id
      );
      if (response?.status === 200) {
        setUserList((prev) => [
          response.data,
          ...prev.filter((el) => el.id !== response.data.id),
        ]);
        setShowUserForm(null);
      }
    }
    setIsSubmitting(false);
  };

  const onCancel = () => {
    setShowUserForm("");
    setUserList("");
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={
        <ModalHeader
          title={`${t("MERCHANT.MANAGE_USERS_FOR")} ${merchantDetails?.name}`}
          breadcrumbs={BREADCRUMB_MERCHANT_MANAGE_USERS(t)}
        />
      }
      open={isOpen}
      onCancel={onCancel}
      footer={null}
      className={styles["user-modal"]}
      width={900}
      maskClosable={false}
    >
      {!!showUserForm ? (
        <Form
          layout="vertical"
          requiredMark="false"
          preserve={false}
          onFinish={onSubmit}
          form={form}
          disabled={isSubmitting}
        >
          <Item name="id" hidden />
          <Item
            name="name"
            label={t("NAME")}
            rules={[
              {
                required: true,
                message: t("MERCHANT.NAME_ERROR"),
              },
            ]}
          >
            <Input />
          </Item>
          <Item
            name="email"
            label={t("EMAIL")}
            rules={[
              {
                type: "email",
                message: t("MERCHANT.EMAIL_ERROR"),
              },
              {
                required: true,
                message: t("MERCHANT.EMAIL_ADDRESS_REQUIRED"),
              },
            ]}
          >
            <Input />
          </Item>
          <Flex gap={20} style={{ marginTop: 40 }}>
            <Button
              buttonType="cancel"
              isFullWidth
              onClick={() => {
                form.resetFields();
                setShowUserForm("");
              }}
              disabled={isSubmitting}
            >
              {t("CANCEL")}
            </Button>
            <Button
              type="primary"
              isFullWidth
              htmlType="submit"
              loading={isSubmitting}
            >
              {t("SUBMIT")}
            </Button>
          </Flex>
        </Form>
      ) : (
        <>
          <Button
            buttonType="primary"
            onClick={() => setShowUserForm("create")}
            className={styles["create-user-btn"]}
          >
            {t("MERCHANT.CREATE_USER")}
          </Button>
          <Table
            columns={columns}
            dataSource={userList}
            loading={isFetchingUsers}
            pagination={false}
          />
        </>
      )}
    </Modal>
  );
};

export default ManageUsers;
