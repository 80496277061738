import { useEffect, useState } from "react";
import { Empty, message, Select, Spin } from "antd";
import { t, useTranslation } from "react-i18next";

import { getUploadedUsersReport } from "../reports/apiUtils";

const Reports = ({ loadingStorefronts, storefronts }) => {
  const [iframeUrls, setIframeUrls] = useState();
  const [loadingUrls, setLoadingUrls] = useState();
  const [selectedStorefront, setSelectedStorefront] = useState();
  const [statusFilter, setStatusFilter] = useState(null);

  const { t } = useTranslation();

  const fetchUrls = async () => {
    setLoadingUrls(true);
    setStatusFilter("successUrl");
    const res = await getUploadedUsersReport(selectedStorefront);
    if (res.status === 200) {
      setIframeUrls(res.data);
    } else {
      message.error({
        content: t("S"),
        duration: 3,
        key: "error",
      });
    }
    setLoadingUrls(false);
  };

  useEffect(() => {
    if (selectedStorefront) fetchUrls();
  }, [selectedStorefront]);

  return (
    <>
      <div>
        <Select
          options={storefronts}
          loading={loadingStorefronts}
          value={selectedStorefront}
          onSelect={(val) => setSelectedStorefront(val)}
          style={{ marginRight: 20, width: 200 }}
          placeholder={t("MERCHANT.SELECT_STOREFRONT")}
        />
        <Select
          style={{ width: 200 }}
          placeholder={t("MERCHANT.SELECT_STATUS")}
          onSelect={(val) => setStatusFilter(val)}
          disabled={!storefronts}
          value={statusFilter}
          options={[
            { label: t("SUCCESSFUL"), value: "successUrl" },
            { label: t("FAILED"), value: "failedUrl" },
          ]}
        />
      </div>
      {!!loadingUrls ? (
        <Spin style={{ margin: "80px auto", width: "100%" }} />
      ) : !iframeUrls ? (
        <Empty
          description={t("MERCHANT.NO_REPORTS")}
          style={{ marginTop: 120 }}
        />
      ) : (
        <iframe
          title="Reports"
          src={iframeUrls[statusFilter]}
          frameBorder={0}
          allowTransparency
          style={{ width: "100%", height: "70vh", marginTop: 30 }}
        />
      )}
    </>
  );
};

export default Reports;
