import axios from "axios";

import constant from "../../../../config/constant";

const baseURL = constant.config.REACT_API_HOST;

export const getAllPressReleaseList = async (pagination) => {
  const { current, pageSize } = pagination;
  const obj = {
    limit: pageSize,
    offset: pageSize * (current - 1),
  };
  return axios
    .post(`${baseURL}press-releases/all`, obj)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Getting press release in DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const addPressReleaseToDB = async (data) => {
  return axios
    .post(`${baseURL}press-releases`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Add Press Release to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const updatePressReleaseToDb = async (data, id) => {
  return axios
    .put(`${baseURL}press-releases/${id}`, data)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Update press releases to DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};

export const deletePressReleaseFromDb = async (id) => {
  return axios
    .delete(`${baseURL}press-releases/${id}`)
    .then((response) => response)
    .catch((err) => {
      console.log(
        `Delete press releases from DB error: \n\n${err.response?.data?.message}`
      );
      return err.response;
    });
};
