import { useEffect, useState, useMemo } from "react";
import { message, Spin, Space, Tooltip, Popconfirm, DatePicker } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { useAppContext } from "../../../../components/context/app.context";
import {
  PageHeader,
  Button,
  NoData,
  Table,
  Tag,
  Micons,
} from "../../../../components/customAntd";
import { BREADCRUMB_ANNOUNCEMENT } from "../../../../utils/announcements/constant";
import {
  getNewsListForBenefit,
  deleteNotification,
} from "../../benefits/apiUtils";
import { getAllPressReleaseList } from "../pressRelease/apiUtils";

import NewNewsModal from "./newNewsModal";

const News = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [list, setList] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const { currentUserData } = useAppContext();
  const [loadingNews, setLoadingNews] = useState(false);
  const [isNewsModalOpen, setIsNewsModalOpen] = useState(false);
  const [filteredRange, setFilteredRange] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [pressReleases, setPressReleases] = useState([]);
  const [loadingPressReleases, setLoadingPressReleases] = useState(true);
  const { t } = useTranslation();

  const getNews = async () => {
    setLoadingNews(true);
    const response = await getNewsListForBenefit(null);
    if (response?.status === 200) {
      setFilteredList(response.data);
      setList(response.data);
    } else {
      message.error({
        content: t("BENEFIT_NOTIFICATION.FETCH_NEWS_ERROR"),
        key: "get-error",
        duration: 2,
      });
    }
    setLoadingNews(false);
  };

  const getPressReleases = async () => {
    const response = await getAllPressReleaseList({
      pageSize: 1000,
      current: 1,
    });
    if (response?.data?.data) {
      setPressReleases(response.data.data);
    } else {
      message.error({
        content: "Couldn't fetch press release",
        key: "get-error",
        duration: 2,
      });
    }
    setLoadingPressReleases(false);
  };

  useEffect(() => {
    getNews();
    getPressReleases();
  }, []);

  const handleFilteredList = () => {
    const filteredList = list.filter((item) => {
      const scheduledDate = dayjs(item.scheduledDate);
      const startDate =
        filteredRange && filteredRange[0] ? dayjs(filteredRange[0]) : null;
      const endDate =
        filteredRange && filteredRange[1] ? dayjs(filteredRange[1]) : null;

      return (
        (!startDate || scheduledDate.isAfter(startDate, "day")) &&
        (!endDate || scheduledDate.isBefore(endDate, "day"))
      );
    });

    setFilteredList(filteredList);
  };

  useEffect(() => {
    handleFilteredList();
  }, [filteredRange]);

  const handleDelete = async (id) => {
    setLoadingNews(true);
    const response = await deleteNotification(id);
    if (response?.status === 200) {
      setList((prev) => prev.filter((el) => el.id !== response.data.id));
      message.success({
        content: t("BENEFIT_NOTIFICATION.NEWS_DELETED_SUCCESSFULLY"),
        key: "success",
        duration: 2,
      });
    } else {
      message.error({
        content: `${t("SOMETHING_WENT_WRONG")} ${t("TRY_AGAIN_LATER")}`,
        key: "error",
        duration: 2,
      });
    }
    setLoadingNews(false);
  };

  const handleDateFilterChange = (dates) => {
    setFilteredRange(dates);
  };

  const columns = useMemo(
    () =>
      [
        {
          title: t("TITLE"),
          dataIndex: "title",
          key: "title",
          width: "15%",
          sorter: (a, b) => a.title.length - b.title.length,
        },
        {
          title: t("SENT DATE"),
          key: "scheduledDate",
          dateIndex: "scheduledDate",
          render: (_, { scheduledDate }) => (
            <>
              <Tag isDisabled>
                {dayjs(scheduledDate).format("MM/DD/YYYY hh:mm A")}
              </Tag>
            </>
          ),
          sorter: (a, b) =>
            dayjs(a.scheduledDate).valueOf() - dayjs(b.scheduledDate).valueOf(),
          filterDropdown: ({ confirm }) => (
            <div>
              <DatePicker.RangePicker
                value={filteredRange}
                onChange={handleDateFilterChange}
                onOk={() => {
                  confirm();
                }}
                showTime={{ format: "HH:mm" }}
                style={{ padding: "8px" }}
              />
            </div>
          ),
          onFilter: (_, record) => {
            const scheduledDate = dayjs(record.scheduledDate);
            const startDate = filteredRange[0] ? dayjs(filteredRange[0]) : null;
            const endDate = filteredRange[1] ? dayjs(filteredRange[1]) : null;

            return (
              (!startDate || scheduledDate.isSameOrAfter(startDate, "day")) &&
              (!endDate || scheduledDate.isSameOrBefore(endDate, "day"))
            );
          },
        },

        !currentUserData?.merchants?.length && {
          title: t("STOREFRONTS"),
          key: "Storefront",
          dataIndex: "Storefront",
          render: (_, { storefront }) => storefront.title,
        },
        {
          title: t("BENEFIT_NOTIFICATION.USER_CRITERIA"),
          key: "userCriteria",
          dataIndex: "userCriteria",
        },
        {
          title: t("ACTION"),
          key: "action",
          width: "13%",
          render: (_, record) => (
            <Space size="middle">
              <Button type="link">
                <Tooltip title={t("EDIT")}>
                  <Micons
                    icon="edit"
                    className="table-action-icon"
                    onClick={() => {
                      setCurrentRecord(record);
                      setIsNewsModalOpen(true);
                    }}
                  />
                </Tooltip>
              </Button>
              {(dayjs(record.scheduledDate).isAfter(dayjs()) ||
                record.level === "News") && (
                <Popconfirm
                  title={t("BENEFIT_NOTIFICATION.NEWS_DELETE_CONFIRMATION")}
                  onConfirm={() => handleDelete(record.id)}
                  onCancel={() => {}}
                  okText={t("YES")}
                  cancelText={t("NO")}
                  placement="topRight"
                  arrowPointAtCenter
                >
                  <Button type="link">
                    <Tooltip title={t("DELETE")}>
                      <Micons icon="delete" className="table-action-icon" />
                    </Tooltip>
                  </Button>
                </Popconfirm>
              )}
            </Space>
          ),
        },
      ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, filteredRange]
  );

  return (
    <>
      <PageHeader
        title={t("NEWS")}
        breadcrumbs={BREADCRUMB_ANNOUNCEMENT(t, t("NEWS"))}
        extra={
          <div style={{ display: "flex" }}>
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOpen(true);
                setIsNewsModalOpen(true);
              }}
            >
              {t("NEW_NEWS")}
            </Button>
          </div>
        }
      />

      {loadingNews ? (
        <Spin size="small" style={{ marginTop: 30, width: "100%" }} />
      ) : list.length > 0 ? (
        <Table
          columns={columns}
          dataSource={filteredList}
          rowKey={(record) => record.id}
          locale={{
            triggerDesc: t("SORT_DESCENDING"),
            triggerAsc: t("SORT_ASCENDING"),
            cancelSort: t("CANCEL_SORT"),
          }}
          overlay={filteredList.length === 0 ? <NoData /> : null}
        />
      ) : (
        <NoData />
      )}

      <NewNewsModal
        isOpen={isNewsModalOpen}
        currentRecord={currentRecord}
        getNews={getNews}
        onCancel={() => {
          setCurrentRecord(null);
          setIsNewsModalOpen(false);
        }}
        loadingPressReleases={loadingPressReleases}
        pressReleases={pressReleases}
      />
    </>
  );
};

export default News;
